import React, { useState, useEffect, useContext } from 'react';
import Login from './Login';
import Menu from './Menu';
import Swal from 'sweetalert2'
import { Panel } from './Panel';
import { AppContext } from './AppContext';
import { Button, Container, Nav, NavItem, NavLink, Popover, PopoverBody, Input } from 'reactstrap';
import classnames from 'classnames';
import { Grupos } from './Grupos';
import { GruposAlumno } from './GruposAlumno';
import { Grupo } from './Grupo';
import { GrupoAlumno } from './GrupoAlumno';
import { Materias } from './Materias';
import { Materia } from './Materia';
import { Encuesta } from './Encuesta';
import { Programa } from './Programa';
import { Tecnicas } from './Tecnicas';
import { Estrategias } from './Estrategias';
import { Modulos } from './Modulos';

class App extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      u: { id: 0, nombre: 'Demo', admin: false },
      lg: false,
      opc: '',
      txt:'',
      tipo:'',
      verMenu:false,
      nombreEmpresa:'',
      urlws : '',
      popOverOpen:false,
      activeTab:0,
      tabs:[],
      ids:[],
      componenteActivo:0,
      componentes: [
      ]
    }
  }
  
  componentDidMount() {
    const edoGlobal = this.context;
    this.setState({...edoGlobal});
    if(this.state.opc != "")
      this.opcionSeleccionada(this.state.opc,this.state.opc)
  }
  grupoSeleccionadoP = (gpo) => {
    let opc = "";
    let txt = gpo.Id;
    for (let i = 0; i < this.state.tabs.length; i++) {
      if(this.state.ids[i] === gpo.Id ){
        this.setState({activeTab:i});
        return;
      }
    }
    this.agregar(<Grupo grupo={gpo} />);
    this.setState({
      tabs: this.state.tabs.concat(gpo.Carrera + '-' + gpo.Gpo + '-' + gpo.Materia),
      ids: this.state.ids.concat(txt)
    });
    this.setState({ opc,txt });
  }
  grupoSeleccionadoA = (gpo) => {
    let opc = "";
    let txt = gpo.Id;
    for (let i = 0; i < this.state.tabs.length; i++) {
      if(this.state.ids[i] === gpo.Id ){
        this.setState({activeTab:i});
        return;
      }
    }
    this.agregar(<GrupoAlumno grupo={gpo} equipos={[]} matricula={this.state.u.id} setCargando={null} />);
    this.setState({
      tabs: this.state.tabs.concat(gpo.Materia),
      ids: this.state.ids.concat(txt)
    });
    this.setState({ opc,txt });
  }
  materiaSeleccionada = (mat) => {
    let opc = "";
    let txt = "M" + mat.Id;
    for (let i = 0; i < this.state.tabs.length; i++) {
      if(this.state.ids[i] === txt ){
        this.setState({activeTab:i});
        return;
      }
    }
    this.agregar(<Programa id={mat.Programa} nombre={mat.Nombre} materia={mat} version={1} />);

    this.setState({
      tabs: this.state.tabs.concat(mat.Clave),
      ids: this.state.ids.concat(txt)
    });
    this.setState({ opc,txt });
  }
  opcionSeleccionada = (opc,txt) => {
    const rpt = opc;
    let urlws = this.state.urlws;
    for (let i = 0; i < this.state.tabs.length; i++) {
      if(this.state.tabs[i] === txt ){
        this.setState({activeTab:i, txt});
        return;
      }
    }
    if (opc === "grupos")
      this.agregar(<Grupos grupoSeleccionado={this.grupoSeleccionadoP} seleccionar={false} />);
    else if (opc === "silabos"){
      this.setState({verMenu:true});
      this.agregar(<Materias verDetalle={this.materiaSeleccionada} />);
    }
    else if (opc === "estrategias")
      this.agregar(<Estrategias />);
    else if (opc === "tecnicas")
      this.agregar(<Tecnicas />);
    else if (opc === "encuesta")
      this.agregar(<Encuesta />);
    else
       return;
    this.setState({
        tabs: this.state.tabs.concat(txt),
        ids: this.state.ids.concat(txt)
      });
    opc = "";
    this.setState({ opc,txt });
  }
  agregar = (componente) => {
    this.setState({
      activeTab:this.state.tabs.length,
      componentes: this.state.componentes.concat(componente)
    });
  }
  toggle = (i) =>{
    const txt = this.state.tabs[i];
    this.setState({activeTab:i, componenteActivo:i, txt});
  }
  cerrar = (i) =>{
    if(i == 0)
       return;
    this.state.componentes[i] = null;
    this.state.tabs[i] = null;
    this.state.ids[i] = null;
    this.setState({activeTab:this.state.tabs.length-1});
  }
  iniciar = (u) => {
    const edoGlobal = this.context;
    const usu = { id: u.Id, nombre: u.Nombre, admin: u.Admin };
    this.setState({ lg: true, u: usu, tipo: u.Tipo, institucion: u.Institucion, nombreEmpresa:u.NombreInst, asistenciaDefault: u.AsistenciaDefault, pwd: u.Pwd });
    edoGlobal.setUsuario(u);
    if (u.Tipo === "P") {
      this.setState({
        tabs: ['Modulos'],
        ids: ['Modulos'],
        componentes: [
          <div><Modulos opcionSel={(opc,nombre)=> this.opcionSeleccionada(opc,nombre)} /></div>,
        ]
      });
    }
    if (u.Tipo === "A") {
      this.setState({
        tabs: ['Cursos'],
        ids: ['Cursos'],
        componentes: [
          <div><GruposAlumno grupoSeleccionado={this.grupoSeleccionadoA} seleccionar={false} /></div>
        ]
      });
    }
  }
  togglePopOver = () => {
    this.setState({popOverOpen: !this.state.popOverOpen});
  }    
  guardarConfiguracion = (listaDefault, pwd) =>{
    this.setState({popOverOpen: false, asistenciaDefault : listaDefault, pwd : pwd});
  }
  mostrarManual = () =>{    
    const edoGlobal = this.context;
    let url = `${edoGlobal.urlws}content/paal.pdf`;
    window.open(url, '', '');      
  }
  render() {
    let {opc} = this.state;
    const estilo = { visibility: this.state.lg ? 'visible' : 'hidden' };
    if(this.state.lg){
      document.body.classList.add('body');
      document.body.classList.remove('fondoInicial');
    }
    return (
      <div>
        {this.state.lg ? null : 
          <Container>
            <br /><br /><br />
            <br /><br /><br />
            <div className="row" >
              <div className="col">
                <br />
                <br />
                <br />
                <br />
                <img src="/imagenes/fondoInicial.png" width={300} />
              </div>
              <div className="col-3">                
                <br/><br/><br/><br/>
                <p style={{fontSize:24}}>paal ya se encuentra en la ubicacion oficial <a style={{color:'maroon'}} href="https://paal.uadeo.mx">https://paal.uadeo.mx</a></p>
              </div>
              <div className="col-5">
                <br /><br /><br />
                <img src="/imagenes/paal.jpg" alt="logo" width={400} />
                <br/><br/>
                <Button onClick={this.mostrarManual} className='botonLince' size="sm" color="secondary" outline title="manual de usuario" >manual de usuario</Button>
              </div>
            </div>
          </Container>
        }
        <div style={estilo} className="colorBlanco">
          <div className="row" >
            <div className="col-2">
              <img src="/imagenes/logo.png" alt="logo" />
            </div>
            <div className="col">
              <div className="row">
                <div className="col-10">
                {this.state.verMenu &&
                  <Menu tipo={this.state.tipo} opcionSeleccionada={this.opcionSeleccionada} /> 
                }
                </div>
              </div>
                <div className="row">
                  <div className="col-7">
                  </div>
                  <div className="col-2">
                  </div>
                  <div className="col-2">
                  </div>
                </div>
            </div>
            <div className="col-3">
              <span style={{ textAlign:'left', color: '#000', fontWeight:'bolder' }}>
                <button className="btn btn-sm" id="btnPwd"><img src="imagenes/gear.png"/></button>
                <Popover trigger="legacy" placement="bottom"  target="btnPwd" isOpen={this.state.popOverOpen} toggle={this.togglePopOver}>
                  <PopoverBody>
                    <div>
                      <ConfiguraUsuario tipo={this.state.tipo} institucion={this.state.institucion} id={this.state.u.id} asistenciaDefault={this.state.asistenciaDefault} pwd={this.state.pwd} guardarConfiguracion={this.guardarConfiguracion}/>
                    </div>
                  </PopoverBody>
                </Popover>
                {this.state.u.nombre}
                <br/>
                <span style={{color:'maroon'}}>{this.state.nombreEmpresa}</span>
                </span>
            </div>
          </div>
          {this.state.lg ?
            <div id="content" style={{ width: '100%', padding:10 }}>
              <div>
                <Nav tabs>
                  {this.state.tabs.map((o, i) =>
                    o == null ? null :
                      <NavItem key={i}>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === i })}
                          onClick={() => { this.toggle(i); }}>
                          <span>{o}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          {i > 0 &&
                            <a href="#"><b><span onClick={() => { this.cerrar(i) }}><img src="/imagenes/circle-x.png" alt="cerrar" /></span></b></a>
                          }
                        </NavLink>
                      </NavItem>
                  )}
                </Nav>
              </div>
              <div style={{padding:4}}>
              {
                this.state.componentes.map((o, i) => {
                  const k = "k" + i;
                  const visible = i == this.state.activeTab ? 'block' : 'none';
                  return <div key={k} style={{ display: visible }}>{o}</div>
                })
              }
              </div>
            </div>      
          :null
          }
        </div>
      </div>
    )
  }
}

function ConfiguraUsuario(props){
  const edoGlobal = useContext(AppContext);

  let [editando, setEditando] = useState(false);
  let [asistenciaDefault, setAsistenciaDefault] = useState(false);
  let [pwdAct, setPwdAct] = useState("");
  let [pwdNuevo, setPwdNuevo] = useState("");
  let [pwdConf, setPwdConf] = useState("");
  let [msg, setMsg] = useState("");

  useEffect(() => {
    if(props.tipo == "A")
      setEditando(true);
    setAsistenciaDefault(props.asistenciaDefault);
  }, []);

  function validar(){
    if(pwdAct !== props.pwd)
      msg = "contraseña actual incorrecta";
    else if(pwdNuevo == "" || pwdNuevo.length < 4)
      msg = "la contraseña debe tener al menos 4 caracteres";
    else if(pwdNuevo != pwdConf)
      msg = "confirmacion de contraseña incorrecta";
    else if(pwdNuevo == pwdAct)
      msg = "la nueva contraseña es igual a la contraseña actual";
    setMsg(msg);
    return msg === "";
  }

  async function guardar(){
    if(editando && !validar())
      return;
    const dato = {
      Tipo:props.tipo,
      Institucion : props.institucion,
      Id : props.id,
      AsistenciaDefault: asistenciaDefault ? "S":"N", 
      Pwd: editando ? pwdNuevo : props.pwd
    };
    let url = `${edoGlobal.urlws}/profesor/wsCambiarPerfilProfesor`;
    const json = JSON.stringify(dato);
    const resp = await fetch(url, {
        method: 'POST', // or 'PUT'
        body: json // data can be `string` or {object}!
    });
    const res = await resp.text();
    if(res == "ok")
      props.guardarConfiguracion(asistenciaDefault, pwdNuevo);
    else
      setMsg(res);
  }
  return (
    <Panel titulo={props.tipo == "A" ? "perfil de alumno":"perfil del profesor"}>
      <div>
         {props.tipo !== "A" && 
         <div>
            <input type="checkbox" checked={asistenciaDefault} onChange={(e) => setAsistenciaDefault(e.target.checked)} />
            <span>  Asistencia por default al tomar lista </span>
            <hr/>
        </div>
        }
        {editando &&
        <div> 
          <span>contraseña actual</span>
          <Input size="sm" type="password" value={pwdAct} onChange={(e) => setPwdAct(e.target.value)} />
          <span>nueva contraseña</span>
          <Input size="sm" type="password" value={pwdNuevo} onChange={(e) => setPwdNuevo(e.target.value)} />
          <span>confirma contraseña</span>
          <Input size="sm" type="password" value={pwdConf} onChange={(e) => setPwdConf(e.target.value)} />
          <hr/>
        </div>
        }
          <Button size="sm" onClick={guardar} outline>Aceptar</Button>
          <span>&nbsp;&nbsp;</span>
          {!editando && <Button size="sm" outline onClick={()=> setEditando(true)}>Cambiar contraseña</Button>}
          <br/><span className='rojo'>{msg}</span>
      </div>
    </Panel>
  )
}
export default App;