import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Input, Row, Table, Button, Modal, ModalHeader, ModalBody, CardText, Container, Tooltip, Card, CardBody } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Grupos } from './Grupos';
import { BiCheck, BiRefresh } from 'react-icons/bi';
import { MdEditCalendar } from 'react-icons/md';

export const GrupoSesiones = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [fechaCap,setFechaCap] = useState(new Date().yyyymmdd('-'));
    let [texto,setTexto] = useState("");
    let [sesiones,setSesiones] = useState([]);
    let [capturando,setCapturando] = useState(false);
    let [cambios,setCambios] = useState(0);
    let [indiceActivo,setIndiceActivo] = useState(-1);

    useEffect(() => {
        (async () => {
            cargarSesiones();
        })();
    }, []);

    function setCargando(v){
        props.setCargando(v);
    }
    async function cargarSesiones(){
        if(sesiones.length !== 0)
           return;
        setCapturando(false);
        setCargando(true);
        let url = `${edoGlobal.urlws}/grupo/sesiones/${props.grupo.Id}`;
        let resp = await fetch(url);
        const s = await resp.json();
        if(!props.consulta){
            for(let i = 0; i < s.length; i++)
                if(s[i].FechaReal == ""){
                    indiceActivo = i;
                    break;
                }
            setIndiceActivo(indiceActivo);
        }
        setCargando(false);
        setSesiones(s);
    }
    async function sesionRealizada(i){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: '¿ se llevo a cabo la sesión ' + sesiones[i].Sesion + ' ?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28B463',
            cancelButtonColor: '#A93226',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                sesionRealizadaB(i);
        });
    }
    async function sesionRealizadaB(i){
        let url = `${edoGlobal.urlws}/grupo/sesionRealizada?grupo=${props.grupo.Id}&sesion=${sesiones[i].Sesion}`;
        setCargando(true);
        let resp = await fetch(url);
        setCargando(false);
        let res = await resp.text();
        if(res == "ok"){
            sesiones = [];
            cargarSesiones();
        }else
           Swal.fire('Atencion',res,'error');    
    }

    async function actualizarSesiones(){
        let url = `${edoGlobal.urlws}/grupo/crearSesiones/${props.grupo.Id}`;
        setCargando(true);
        let resp = await fetch(url);
        setCargando(false);
        let res = await resp.text();
        if(res == "ok"){
            sesiones = [];
            cargarSesiones();
        }else
           Swal.fire('Atencion',res,'error');    
    }
    function verProgramaIndicativo(){
        //var url = clases.Servicio.URLBase + "/content/programas/" + grupo.Institucion + "/" + grupo.ClaveMateria +".pdf";
        console.log(props.grupo);
        let url = `${edoGlobal.urlws}/content/programas/${edoGlobal.usuario.Institucion}/${props.grupo.Clave}.pdf`;
        window.open(url, '', '');    
    }
    return(
        <div>
            <Row>
                <div className='col'>
                    <table>
                        <tr style={{ verticalAlign: 'center' }}>
                            <td><span>Sesiones</span></td>
                            <td>
                                <span>&nbsp;&nbsp;</span>
                                {
                                    //props.grupo.Abierto && <img onClick={()=>{editar({Id:0,Fecha: fechaCap,Texto:''})}} src="imagenes/add.png" alt="nuevo" title="nuevo tema" />
                                }
                                {props.grupo.Abierto && <Button onClick={actualizarSesiones} color="secondary" size="sm" className='botonLince' outline><BiRefresh size={20} color='maroon' title="actualizar lista de temas" /></Button>}
                            </td>
                            <td><span>&nbsp;&nbsp;</span></td>
                            <td><span>&nbsp;&nbsp;</span></td>
                            <td>
                                {false &&   <Button size="sm" color="secondary" outline onClick={verProgramaIndicativo}>Ver programa indicativo</Button>}
                            </td>
                        </tr>
                    </table>
                    <br/>
                    <Table striped hover size="sm" >
                        <thead>
                            <td>Sesión</td>
                            <td>Fecha</td>
                            <td>Temas</td>
                            <td>Actividades</td>
                            <td className='celdaBoton'></td>
                            <td className='celdaBoton'></td>
                        </thead>
                        <tbody>
                            {sesiones.map((o,i) =><tr key={i}><SesionGrupoRenglon sesion={o} indice={i} botones={i == indiceActivo} sesionRealizada={sesionRealizada} /></tr>)}
                        </tbody>
                    </Table>
                </div>
            </Row>
        </div>
    )
}
const SesionGrupoRenglon = ({sesion, indice, botones, sesionRealizada}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    let temas = '';
    let actividades = '';
    if (sesion.Temas.length > 0) {
        let sepa = '';
        for (let i = 0; i < sesion.Temas.length; i++) {
            temas = temas + sepa + '&#10151;  ' + sesion.Temas[i];
            sepa = '<br/>';
        }
    }
    if (sesion.Actividades.length > 0) {
        let sepa = '';
        for (let i = 0; i < sesion.Actividades.length; i++) {
            actividades = actividades + sepa + '&#10151;  ' + sesion.Actividades[i];
            sepa = '<br/>';
        }
    }
    const reprogramar = false;
    return (
        <>
            <td>
                <b className='rojo'>{sesion.Sesion} </b>
            </td>
            <td>
                <b>{sesion.Fecha}</b>
            </td>
            <td>
                <div>
                    {sesion.Temas.length > 0 &&
                        <div>
                            <b className='rojo'>Temas y subtemas</b>
                            <ul>
                                {sesion.Temas.map((o,i)=> <li className='liNoBullet' key={i}>&#10151;{o}</li>)}
                            </ul>
                        </div>
                    }
                </div>
            </td>
            <td>
                <div>
                    {sesion.Actividades.length > 0 &&
                        <div>
                            <b className='rojo'>Actividades</b>
                            <ul>
                                {sesion.Actividades.map((o,i)=> {
                                    let idToolTip = 'act-' + sesion.Sesion + '-' + i;
                                    return(
                                        <li className='liNoBullet' key={i} id={idToolTip}>
                                        &#10151;{o}
                                        <Tooltip style={{backgroundColor:'white', color:'black', border:'1px solid maroon'}} isOpen={tooltipOpen} toggle={toggle} target={idToolTip} autohide={false}>
                                            <b>{sesion.Tipo[i]}</b><hr style={{borderColor  :'maroon'}}/>
                                            <span>{sesion.Texto[i]}</span>
                                        </Tooltip>
                                        </li>)}    
                                    )
                                }
                            </ul>
                        </div>
                    }
                </div>
            </td>
            {botones &&
                <>
                    <td className='celdaBoton'><Button size="sm" outline className='botonLince' onClick={() => sesionRealizada(indice)}><BiCheck size={20} color='maroon' title="sesion concluida" /></Button></td>
                    <td className='celdaBoton'>{reprogramar &&<Button outline size="sm" className='botonLince'><MdEditCalendar size={20} color='maroon' title="reprogramar sesion" /></Button>}</td>
                </>
            }
            {!botones &&
                <>
                <td colSpan={2}>
                    {sesion.FechaReal != "" && <b className='rojo'><br/>REALIZADA</b>}
                </td>
                </>
            }
        </>
    )
}