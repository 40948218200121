import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Input, Row, Table, Button, Modal, ModalHeader, ModalBody, CardText, Container } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Grupos } from './Grupos';
import { TiPlus } from 'react-icons/ti';
import { IoIosTrash, IoMdCreate } from 'react-icons/io';

export const GrupoAnuncios = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [texto,setTexto] = useState("");
    let [anuncios,setAnuncios] = useState([]);
    let [anuncio,setAnuncio] = useState({Id:0, Texto:''});
    let [capturando,setCapturando] = useState(false);
    let [cambios,setCambios] = useState(0);

    useEffect(() => {
        (async () => {
            cargarAnuncios();
        })();
    }, []);

    function setCargando(v){
        props.setCargando(v);
    }
    async function cargarAnuncios(){
        if(anuncios.length !== 0)
           return;
        setCapturando(false);
        setCargando(true);
        let url = `${edoGlobal.urlws}/anuncio/anuncios?grupo=${props.grupo.Id}&alumno=`;
        let resp = await fetch(url);
        const a = await resp.json();
        setCargando(false);
        setAnuncios(a);
    }
    function editar(t){
        if(!props.grupo.Abierto){
            Swal.fire('Atencion','el grupo esta cerrado','error');    
            return;
        } 
        setAnuncio(t);
        setTexto(t.Texto);
        setCapturando(true);
    }
    function eliminar(t){
        if(!props.grupo.Abierto){
            Swal.fire('Atencion','el grupo esta cerrado','error');    
            return;
        } 
        Swal.fire('Atencion',"Opcion aun en desarrollo",'info');
    }
    async function guardar(){
        const dato = {
            Id : anuncio.Id,
            Grupo : props.grupo.Id,
            Alumno :'',
            Fecha:0,
            Texto :texto
        }
        let url = `${edoGlobal.urlws}/anuncio/wsguardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            anuncios = [];
            cargarAnuncios();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    return(
        <div>
            <Row>
                <div className='col'>
                    <table>
                        <tr style={{ verticalAlign: 'center' }}>
                            <td><span>Anuncios</span></td>
                            <td><span>&nbsp;&nbsp;</span>
                              {props.grupo.Abierto && <TiPlus size={20} color='maroon' onClick={()=>{editar({Id:0, Texto:''})}} title="nuevo anuncio" />}
                              </td>
                        </tr>
                    </table>
                    <Table size="sm" hover striped>
                        <thead>
                            <tr>
                                <th>Texto</th>
                                <th className='celdaBoton'></th>
                                <th className='celdaBoton'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {anuncios.map((o, i) => {
                                return (
                                    <tr key={i} >
                                        <td>{o.Texto}</td>
                                        <td><IoMdCreate size={20} color='maroon' onClick={()=> editar(o)}  title="editar" /></td>
                                        <td><IoIosTrash size={20} color='maroon' onClick={()=> eliminar(o)} title="eliminar" /></td>
                                     </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                <Modal isOpen={capturando} toggle={()=>setCapturando(false)}>
                <ModalHeader>
                    {anuncio.Id == 0 ? <span>Nuevo Anuncio</span> : <span>Editar Anuncio</span>}
                </ModalHeader>
                <Container>
                    <Row>
                        <div className='col'>
                            <span>Anuncio</span>
                            <Input type="textarea" value={texto} onChange={(e)=>setTexto(e.target.value)} />
                        </div>
                    </Row>
                    <br/>
                    <Row>
                        <div className='col-2'></div>
                        <div className='col'>
                            <Button size="sm" color="secondary" outline onClick={()=>guardar()}>Guardar</Button>
                        </div>
                    </Row>
                </Container>
                <br/>
            </Modal>
            </Row>
        </div>
    )
}