import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Modal, Input,InputGroup, Badge, Button, Table, Row, Card, CardBody, CardTitle, CardSubtitle, CardText, ModalBody, ModalHeader } from 'reactstrap';
import { AppContext } from './AppContext';
import Swal from 'sweetalert2'

export const Encuesta = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [encuesta,setEncuesta] = useState({Areas:[]});
    const [cargando,setCargando] = useState(false);
    const [cambio,setCambio] = useState(false);

    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/encuesta/aplicar?inst=${edoGlobal.institucion}&alumno=${edoGlobal.usuario.Id}`;
            let resp = await fetch(url);
            const enc = await resp.json();
            setEncuesta(enc);
            setCargando(false);
        })();
    }, []);

    function cambiar(a, r, txt){
        encuesta.Areas[a].Reactivos[r].Respuesta = txt;
        setCambio(!cambio);
        console.log(encuesta);
    }
    async function guardar(){
        let mat = 303;
        let url = `${edoGlobal.urlws}/encuesta/wsguardar/${mat}`;
        setCargando(true);
        const json = JSON.stringify(encuesta);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
        }
        else
            Swal.fire('Atencion',res,'error');
    }

    return(
        <div>
            <Button size="sm" color="success" onClick={guardar} >Guardar</Button>
            {encuesta.Areas.map((o,i)=> <Area cambiar={cambiar} area={o} indice={i} key={i}/>)}
            <Button size="sm" color="success" onClick={guardar} >Guardar</Button>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" className='rojo' >&nbsp;</Spinner>
                </div>
                :
                null
            }
        </div>
    )
}
function Area(props){
    function cambiar(i, txt){
        props.cambiar(props.indice, i, txt);
    }
    return (
        <div>
            <h5>{props.area.Texto}</h5>
            {props.area.Reactivos.map((o,i) =>
              <div key={o.Id}>
                <h6>{o.Texto}</h6>
                <Input size="sm" value={o.Respuesta} onChange={(e) => cambiar(i,e.target.value)} />
              </div>
            )}
            <br />
        </div>
    )
}