import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Modal, Input,InputGroup, Badge, Button, Table, Row, Card, CardBody, CardTitle, CardSubtitle, CardText, ModalBody, ModalHeader } from 'reactstrap';
import { AppContext } from './AppContext';
import Swal from 'sweetalert2'
import {Tabla} from './Tabla';
import { IoIosAdd, IoIosSearch } from 'react-icons/io';
import { IoAddCircle } from 'react-icons/io5';
import { TiPlus } from 'react-icons/ti';

export const Grupos = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [carreras,setCarreras] = useState([]);
    let [grupos,setGrupos] = useState([]);
    let [periodos,setPeriodos] = useState([]);
    let [periodo,setPeriodo] = useState(0);
    let [materias,setMaterias] = useState([]);
    let [idGrupo,setIdGrupo] = useState(0);
    let [idMateria,setIdMateria] = useState(0);
    let [carrera,setCarrera] = useState(0);
    let [filtro,setFiltro] = useState("");
    let [accion,setAccion] = useState("");
    let [nombre,setNombre] = useState("");
    let [aula,setAula] = useState("");
    let [grupo,setGrupo] = useState("");
    let [turno,setTurno] = useState("");
    let [lunes,setLunes] = useState("");
    let [martes,setMartes] = useState("");
    let [miercoles,setMiercoles] = useState("");
    let [jueves,setJueves] = useState("");
    let [viernes,setViernes] = useState("");
    let [ptjeExamen,setPtjeExamen] = useState(0);
    let [ptjeAsistencias,setPtjeAsistencias] = useState(0);
    let [ptjeTareas,setPtjeTareas] = useState(0);
    let [abierto,setAbierto] = useState(false);
    let [verModal,setVerModal] = useState(false);
    let [nombreProf,setNombreProf] = useState("");
    const [cargando,setCargando] = useState(false);
    const [editando,setEditando] = useState(false);
    
    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/periodo/todos/${edoGlobal.institucion}`;
            let resp = await fetch(url);
            const periodos = await resp.json();
            url = `${edoGlobal.urlws}/grupo/carreras/${edoGlobal.institucion}`;
            resp = await fetch(url);
            const carreras = await resp.json();
            setPeriodos(periodos);
            setCarreras(carreras);
            if(periodos.length > 0)
                setPeriodo(periodos[0].Id);
            setCargando(false);
        })();
    }, []);

    async function cargarGrupos(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/grupo/gruposProfesor?institucion=${edoGlobal.institucion}&profesor=${edoGlobal.usuario.Id}&periodo=${periodo}`;
        if(nombreProf != "")
            url = `${edoGlobal.urlws}/grupo/gruposProfesores?institucion=${edoGlobal.institucion}&periodo=${periodo}&nombre=${nombreProf}`;
        let resp = await fetch(url);
        const grupos = await resp.json();
        let gpos = [];
        let col = 1;
        let i = 0;
        for (i = 0; i < grupos.length; i++){
            if(col == 1){
               col = 2;
            }
            else if(col == 2){
                col = 3;
            }
            else{
                gpos.push({a:grupos[i-2], b:grupos[i-1], c:grupos[i]});
                col = 1;
            }
        }
        if(col == 3 && grupos.length > 0)
            gpos.push({a:grupos[grupos.length-2], b:grupos[grupos.length-1], c:null});
        else if(col == 2 && grupos.length > 0)
           gpos.push({a:grupos[grupos.length-1], b:null, c:null});
        setCargando(false);
        setGrupos(gpos);// grupos);
    }
    async function cargarFiltro(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/materia/lista?inst=${edoGlobal.institucion}&filtro=${filtro}`;
        let resp = await fetch(url);
        const m = await resp.json();
        setCargando(false);
        setMaterias(m);
    }
    function nuevoGrupo(){
        setIdGrupo(0);
        setCarrera(0);
        setIdMateria(0);
        setNombre("");
        setAula("");
        setGrupo(0);
        setTurno("");
        setLunes("");
        setMartes("");
        setMiercoles("");
        setJueves("");
        setViernes("");
        setPtjeExamen(0);
        setPtjeAsistencias(0);
        setPtjeTareas(0);
        setEditando(true);
        setAccion("nuevo grupo");
    } 
    async function editar(i,col){
        let gpo = grupos[i].a;
        if(col == 2)
           gpo = grupos[i].b;
        if(col == 3)
           gpo = grupos[i].c;
        setIdGrupo(gpo.Id);// grupos[i].Id);
        setCargando(true);
        let url = `${edoGlobal.urlws}/grupo/cargar/${gpo.Id}`; // grupos[i].Id}`;
        let resp = await fetch(url);
        const o = await resp.json();
        setAccion("editando : " + o.NomMateria);
        setCargando(false);
        setCarrera(o.Carrera);
        setIdMateria(o.Materia);
        setNombre(o.NomMateria);
        setAula(o.Aula);
        setGrupo(o.Gpo);
        setTurno(o.Turno);
        setLunes(o.Lunes);
        setMartes(o.Martes);
        setMiercoles(o.Miercoles);
        setJueves(o.Jueves);
        setViernes(o.Viernes);
        setPtjeExamen(o.PtjeExamen);
        setPtjeAsistencias(o.PtjeAsistencias);
        setPtjeTareas(o.PtjeTareas);
        setEditando(true);
    } 
    function abrir(i,col){
        let gpo = grupos[i].a;
        if(col == 2)
           gpo = grupos[i].b;
        if(col == 3)
           gpo = grupos[i].c;
        props.grupoSeleccionado(gpo);// grupos[i]);
    }
    function selecionaMateria(i){
        setVerModal(false);
        setNombre(materias[i].Nombre);
        setIdMateria(materias[i].Id)
    }

    async function guardar(){
        let inst = edoGlobal.institucion;
        let pE = Number(ptjeExamen);
        let pA = Number(ptjeAsistencias);
        let pT = Number(ptjeTareas);
        let p = pE + pA + pT;
        if(p > 100){
            Swal.fire('Atencion','La suma de la ponderacion es mayor a 100 (' + p + ')','error');
            return;
        }
        const dato = {
            Id : idGrupo, Institucion : edoGlobal.institucion,
            Periodo:periodo, Profesor : edoGlobal.usuario.Id,
            Materia : idMateria, Aula :aula, Gpo : grupo, Turno : turno, Carrera : carrera,
            Lunes : lunes, Martes : martes, Miercoles : miercoles, Jueves : jueves, Viernes : viernes,
            FechaIni:0, FechaFin:0, Clave : '', Examen1:0, Examen2 : 0, Examen3:0,
            PtjeExamen : pE, PtjeAsistencias : pA, PtjeTareas : pT
        }
        let url = `${edoGlobal.urlws}/grupo/wsguardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            setIdGrupo(0);
            setEditando(false);
            cargarGrupos();    
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    /*
    function nuevoPaciente(){
        setIdPac(0);
        setEditando(true);
    } 
    const editar = i =>{
        setIdPac(pacientes[i].I);
        setEditando(true);
    }
    const consultar = i =>{
        setIdPac(pacientes[i].I);
        setConsultando(true);
    }
    const cerrarFormulario = (cargar) =>{
        setEditando(false);
        if(cargar)
           cargarPacientes();
    }
    */
    const estilo1 = { display: editando ? 'none' : 'inline' };
    const estilo2 = { display: editando ? 'inline' : 'none' };
    return(
        <div>
            <Modal size="lg" isOpen={verModal} toggle={()=>setVerModal(false)}>
                <ModalHeader>
                    <span>Buscar asignatura</span>
                </ModalHeader>
                <ModalBody>
                    <Input value={filtro} onKeyPress={(e)=> {if(e.which == 13) cargarFiltro();}} onChange={(e)=> setFiltro(e.target.value)}/>
                    <br/>
                    <Table size="sm" hover striped>
                        <tbody>
                            {materias.map((o,i)=>{ return(
                                <tr key={i} onClick={()=>selecionaMateria(i)}>
                                    <td>{o.Clave}</td>
                                    <td>{o.Nombre}</td>
                                </tr>
                            )})}
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
            <Container style={estilo2}>
                <Row>
                    <div className='col'>
                        <h6>{accion}</h6>
                    </div>
                </Row>
                <Row>
                    <div className='col'>
                        <span>Asignatura</span>
                        <InputGroup size="sm">
                            <Input value={nombre}/>
                            <Button className='botonLince' outline size="sm" onClick={()=>setVerModal(true)}>
                                <IoIosSearch />
                            </Button>
                        </InputGroup>
                    </div>
                    <div className='col-1'>
                        <span></span>
                    </div>
                </Row>
                <Row>
                    <div className='col-1'>
                        <span>Aula</span>
                        <Input size="sm" value={aula} onChange={(e)=>setAula(e.target.value)} />
                    </div>
                    <div className='col-1'>
                        <span>Grupo</span>
                        <Input size="sm" value={grupo} onChange={(e)=>setGrupo(e.target.value)} />
                    </div>
                    <div className='col-3'>
                        <span>Turno</span>
                        <Input type="select" size="sm" value={turno} onChange={(e)=>setTurno(e.target.value)} >
                            <option value={'M'}>Matutino</option>
                            <option value={'V'}>Vespertino</option>
                            <option value={'N'}>Nocturno</option>
                        </Input>
                    </div>
                    <div className='col'>
                        <span>Carrera</span>
                        <Input type="select" size="sm" value={carrera} onChange={(e)=>setCarrera(e.target.value)} >
                            <option value={0}>NO ESPECIFICADA</option>
                            {carreras.map((o,i)=><option key={i} value={o.I}>{o.N}</option>)}
                        </Input>
                    </div>
                    <div className='col-1'>
                        <span></span>
                    </div>
                </Row>
                <br/>
                <table>
                    <tr>
                        <td>Lunes</td>
                        <td><Input type="time" value={lunes} onChange={(e)=>setLunes(e.target.value)} /></td>
                        <td style={{width:30}}><span>&nbsp;&nbsp;</span></td>
                        <td colspan="2" style={{textAlign:'center'}}>Ponderacion</td>
                    </tr>
                    <tr>
                        <td>Martes</td>
                        <td><Input type="time" value={martes} onChange={(e)=>setMartes(e.target.value)} /></td>
                        <td><span>&nbsp;&nbsp;</span></td>
                        <td>% Examen</td>
                        <td><Input type="text" style={{width:80}} value={ptjeExamen} onChange={(e)=>setPtjeExamen(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td>Miercoles</td>
                        <td><Input type="time" value={miercoles} onChange={(e)=>setMiercoles(e.target.value)} /></td>
                        <td><span>&nbsp;&nbsp;</span></td>
                        <td>% Asistencias</td>
                        <td><Input type="text" style={{width:80}} value={ptjeAsistencias} onChange={(e)=>setPtjeAsistencias(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td>Jueves</td>
                        <td><Input type="time" value={jueves} onChange={(e)=>setJueves(e.target.value)} /></td>
                        <td><span>&nbsp;&nbsp;</span></td>
                        <td>% Tareas</td>
                        <td><Input type="text" style={{width:80}} value={ptjeTareas} onChange={(e)=>setPtjeTareas(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td>Viernes</td>
                        <td><Input type="time" value={viernes} onChange={(e)=>setViernes(e.target.value)} /></td>
                        <td><span>&nbsp;&nbsp;</span></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
                <br/>
                <br/>
                <Row>
                    <div className='col-1'>
                        <Button size="sm" color="secondary" className='botonLince' outline onClick={guardar}>Guardar</Button>
                    </div>
                    <div className='col-1'>
                        <Button size="sm" color="secondary" className='botonLince' outline onClick={() => setEditando(false)}>Cancelar</Button>
                    </div>
                </Row>
            </Container>
            <Container style={estilo1}>
                <div className="row">
                    <table>
                        <tr>
                            <td>Periodo&nbsp;</td>
                            <td>
                                <Input type="select" size="sm" value={periodo} onChange={(e) => setPeriodo(e.target.value)} >
                                    <option value={0}>Todos</option>
                                    {periodos.map((o, i) => (
                                        <option key={i} value={o.Id}>
                                            {o.Nombre}
                                        </option>
                                    ))}
                                </Input>
                            </td>
                            <td>
                            {edoGlobal.usuario.Admin && 
                            <input className='form-control form-control-sm' value={nombreProf} onChange={(e) => setNombreProf(e.target.value)} placeholder='nombre del profesor' />
                            }
                            </td>
                            <td>&nbsp;&nbsp;<Button outline className='botonLince' color="secondary" size="sm" onClick={cargarGrupos}><IoIosSearch color='maroon' size={20} /></Button></td>
                            <td>&nbsp;
                            </td>
                        </tr>
                    </table>
                </div>
                { props.seleccionar ? null :
                <Table size="sm" borderless>
                    <tbody>
                {grupos.map((o, i) => {
                    return (
                        <tr key={i} style={{ padding: 15 }}>
                            <td>
                                <Card className="cardGrupo">
                                    <CardBody>
                                        <CardTitle tag="h6">
                                        <a href="#" onClick={() => abrir(i,1)}><span style={{backgroundColor:'white', color:'maroon'}}>{o.a.Materia}</span></a>
                                        </CardTitle>
                                        <span>Profesor : </span><b>{o.a.Profesor}</b>
                                        <div>
                                            <Row>
                                                <div className='col-5'>
                                                    <span>Semestre : </span><b>{o.a.Periodo}</b>
                                                </div>
                                                <div className='col'>
                                                    <span>Grupo : </span><b>{o.a.Gpo}</b>
                                                    <span> Aula : </span><b>{o.a.Aula}</b>    
                                                </div>
                                            </Row>
                                            <span>Carrera : </span><b>{o.a.Carrera}</b>
                                            <span> &nbsp;&nbsp;&nbsp;Horario : </span> <b>{o.a.Horario}</b>
                                            <br />
                                            <br />
                                            <Row>
                                                <div className='col-3'>
                                                    <Button size="sm" color="secondary" className='botonLince' outline onClick={() => abrir(i,1)}>Abrir</Button>
                                                </div>
                                                <div className='col-3'>
                                                    {o.a.Abierto ? <Button size="sm" className='botonLince' color="secondary" outline onClick={() => editar(i,1)}>Editar</Button> : null}
                                                </div>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </td>
                            {o.b != null && <td>
                                <Card className="cardGrupo">
                                    <CardBody>
                                        <CardTitle tag="h6">
                                        <a href="#" onClick={() => abrir(i,2)}><span style={{backgroundColor:'white', color:'maroon'}}>{o.b.Materia}</span></a>
                                        </CardTitle>
                                        <span>Profesor : </span><b>{o.b.Profesor}</b>
                                        <div>  
                                            <Row>
                                                <div className='col-5'>
                                                    <span>Semestre : </span><b>{o.b.Periodo}</b>
                                                </div>
                                                <div className='col'>
                                                    <span>Grupo : </span><b>{o.b.Gpo}</b>
                                                    <span> Aula : </span><b>{o.b.Aula}</b>    
                                                </div>
                                            </Row>
                                            <span>Carrera : </span><b>{o.b.Carrera}</b>
                                            <span> &nbsp;&nbsp;&nbsp;Horario : </span> <b>{o.b.Horario}</b>
                                            <br />
                                            <br />
                                            <Row>
                                                <div className='col-3'>
                                                    <Button size="sm" className='botonLince' color="secondary" outline onClick={() => abrir(i,2)}>Abrir</Button>
                                                </div>
                                                <div className='col-3'>
                                                    {o.b.Abierto ? <Button className='botonLince' size="sm" color="secondary" outline onClick={() => editar(i,2)}>Editar</Button> : null}
                                                </div>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </td>}
                            {o.c != null && <td>
                                <Card className="cardGrupo">
                                    <CardBody>
                                        <CardTitle tag="h6">
                                        <a href="#" onClick={() => abrir(i,3)}><span style={{backgroundColor:'white', color:'maroon'}}>{o.c.Materia}</span></a>
                                        </CardTitle>
                                        <span>Profesor : </span><b>{o.c.Profesor}</b>
                                        <div>
                                            <Row>
                                                <div className='col-5'>
                                                    <span>Semestre : </span><b>{o.c.Periodo}</b>
                                                </div>
                                                <div className='col'>
                                                    <span>Grupo : </span><b>{o.c.Gpo}</b>
                                                    <span> Aula : </span><b>{o.c.Aula}</b>    
                                                </div>
                                            </Row>
                                            <span>Carrera : </span><b>{o.c.Carrera}</b>
                                            <span> &nbsp;&nbsp;&nbsp;Horario : </span> <b>{o.c.Horario}</b>
                                            <br />
                                            <br />
                                            <Row>
                                                <div className='col-3'>
                                                    <Button className='botonLince' size="sm" color="secondary" outline onClick={() => abrir(i,3)}>Abrir</Button>
                                                </div>
                                                <div className='col-3'>
                                                    {o.c.Abierto ? <Button className='botonLince' size="sm" color="secondary" outline onClick={() => editar(i,3)}>Editar</Button> : null}
                                                </div>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </td>}
                        </tr>
                    )
                })
                }
                </tbody>
                </Table>
                }
                { !props.seleccionar ? null :
                <Table size="sm" striped hover borderless>
                    <tbody>
                {grupos.map((o, i) => {
                    return (
                        <tr style={{padding:15}}><td>
                        <Card
                        >
                            <CardBody>
                                <CardTitle tag="h5">
                                    {o.Materia}
                                </CardTitle>
                                <CardText>
                                <Row>
                                        <div className='col-2'>
                                            <span></span>{o.Gpo}
                                        </div>
                                        <div className='col-5'>
                                            {o.Periodo}
                                        </div>
                                        <div className='col-2'>
                                            <span></span>{o.Aula}
                                        </div>
                                    </Row>
                                    {o.Horario}
                                    <br/>
                                    {o.Carrera}
                                    <br/>
                                <Row>
                                    <div className='col-1'>
                                        <Button size="sm" className='botonLince' onClick={()=> abrir(i)}>Abrir</Button>
                                    </div>
                                </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                        </td></tr>
                    )
                })
                }
                </tbody>
                </Table>
                }
            </Container>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" className='rojo' >&nbsp;</Spinner>
                </div>
                :
                null
            }
        </div>
    )
}