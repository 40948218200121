import React from 'react';
import {useState, useContext, useEffect, useRef} from 'react';
import { Input, Row, Table, Button, Badge, Modal, ModalHeader, ModalBody, CardText, Container, Card } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Grupos } from './Grupos';
import { BiRefresh, BiSolidFilePdf } from 'react-icons/bi';
import { IoIosSearch } from 'react-icons/io';
import { BsFillFileEarmarkDiffFill } from 'react-icons/bs';

export const GrupoTrabajos = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [fechaCap,setFechaCap] = useState(new Date().yyyymmdd('-'));
    let [fechaMax,setFechaMax] = useState(new Date().yyyymmdd('-'));
    let [calif,setCalif] = useState("");
    let [equipo, setEquipo] = useState(0);
    let [nombre,setNombre] = useState("");
    let [paginas,setPaginas] = useState("");
    let [unidad,setUnidad] = useState(0);
    let [ra,setRA] = useState(0);
    let [puntos,setPuntos] = useState(0);
    let [comentario,setComentario] = useState("");
    let [individual,setIndividual] = useState(false);
    let [trabajos,setTrabajos] = useState([]);
    let [alumnos,setAlumnos] = useState([{Nombre:''}]);
    let [trabajo,setTrabajo] = useState({Id:0, Fecha:'', FechaMax:'', Nombre:'', Individual:false});
    let [capturando,setCapturando] = useState(false);
    let [enPestana,setEnPestana] = useState(false);
    let [cambios,setCambios] = useState(0);
    let [indice,setIndice] = useState(0);
    let [calificando,setCalificando] = useState(false);
    let [capturandoCalif,setCapturandoCalif] = useState(false);
    let [capturandoCalifEq,setCapturandoCalifEq] = useState(false);
    let [indiceEquipo,setIndiceEquipo] = useState(0);
    let [urlArchivo, setUrlArchivo] = useState("");
    useEffect(() => {
        (async () => {
            cargarTrabajos();
        })();
    }, []);
    function calificarAlumno(i){
        if(!trabajo.Individual){
            setCapturandoCalif(false);
            calificarEquipos()
            return;
        }
        setCapturandoCalifEq(false);
        setIndice(i);
        setCalif(alumnos[i].Calif);
        setComentario(alumnos[i].Comentario);
        setCapturandoCalif(true);
    }
    function calificarEquipos(){
        indice = 0;
        setIndice(0);
        datosEquipo()
        setCapturandoCalifEq(true);
    }
    function datosEquipo(){
        equipo = props.equipos[indiceEquipo].Id;
        for(let i = 0; i < alumnos.length; i++)
            if(alumnos[i].Eq == equipo){
                indice = i;
                setIndice(i);
                setCalif(alumnos[i].Calif);
                setComentario(alumnos[i].Comentario);        
            }
        setEquipo(equipo);
    }
    function keyPress(e){
        if(e.which == 13){
            const x = document.getElementById("txtComenta" + props.grupo.Id);
            x.focus();
        }
    }
    function keyDown(e){
        // F2 - 113
        if(e.keyCode == 113){ 
           guardarCalifTrabajo();
           const x = document.getElementById("txtCalif" + props.grupo.Id);
           x.focus();
       }
    }
    async function guardarCalifTrabajoEq(){
        let url = "";
        setCargando(true);
        for(let i = 0; i < alumnos.length; i++){
            if(alumnos[i].Eq == equipo){
                url = `${edoGlobal.urlws}/trabajo/wsGuardarCalifAlumno/${trabajo.Id}?alumno=${alumnos[i].Alumno}&calif=${calif}`;
                const resp = await fetch(url,{
                    method: 'POST', // or 'PUT'
                    body: comentario
                  });
                const res = await resp.text();        
                if(res === "ok"){
                    alumnos[i].Calif = calif;
                    alumnos[i].Comentario = comentario;
                }        
            }
        }
        setCargando(false);
        equipoSiguiente();
    }
    async function guardarCalifTrabajo(){
        let url = `${edoGlobal.urlws}/trabajo/wsGuardarCalifAlumno/${trabajo.Id}?alumno=${alumnos[indice].Alumno}&calif=${calif}`;
        setCargando(true);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: comentario
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            alumnos[indice].Calif = calif;
            alumnos[indice].Comentario = comentario;
            siguiente();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    function anterior(){
        let i = indice - 1;
        if(i < 0)
           return;
        setUrlArchivo("");
        setIndice(i);
        setCalif(alumnos[i].Calif);
        setComentario(alumnos[i].Comentario);
    }
    function siguiente(){
        let i = indice + 1;
        if(i == alumnos.length)
           return;
        setUrlArchivo("");
        setIndice(i);
        setCalif(alumnos[i].Calif);
        setComentario(alumnos[i].Comentario);
    }
    function equipoAnterior(){
        let i = indiceEquipo - 1;
        if(i < 0)
           return;
        indiceEquipo = i;
        setIndiceEquipo(i);
        datosEquipo()
    }
    function equipoSiguiente(){
        let i = indiceEquipo + 1;
        if(i == props.equipos.length)
           return;
        indiceEquipo = i;
        setIndiceEquipo(i);
        datosEquipo()
    }
    function setCargando(v){
        props.setCargando(v);
    }
    async function cargarTrabajos(){
        if(trabajos.length !== 0)
           return;
        setCapturando(false);
        setCargando(true);
        let url = `${edoGlobal.urlws}/trabajo/trabajos/${props.grupo.Id}`;
        let resp = await fetch(url);
        const a = await resp.json();
        setCargando(false);
        setTrabajos(a);
    }
    async function actualizarTrabajos(){
        let url = `${edoGlobal.urlws}/grupo/agregaTrabajosMateria/${props.grupo.Id}`;
        setCargando(true);
        let resp = await fetch(url);
        setCargando(false);
        let res = await resp.text();
        if(res == "ok"){
            trabajos = [];
            cargarTrabajos();
        }else
           Swal.fire('Atencion',res,'error');    
    }
    function editar(t){
        if(!props.grupo.Abierto){
            Swal.fire('Atencion','el grupo esta cerrado','error');    
            return;
        } 
        setTrabajo(t);
        setUnidad(t.Unidad);
        setRA(t.RA);
        setPaginas(t.Paginas);
        setPuntos(t.Puntos);
        setFechaCap(t.Fecha);
        setFechaMax(t.FechaMax);
        setNombre(t.Nombre);
        setPaginas(t.Paginas);
        setIndividual(t.Individual);
        setCapturando(true);
    }
    async function subir(t){
        if(!props.grupo.Abierto){
            Swal.fire('Atencion','el grupo esta cerrado','error');    
            return;
        } 
        const { value: file } = await Swal.fire({
            title: 'Subir archivo',
            input: 'file',
            inputAttributes: {
              accept: '*/*',
              'aria-label': 'Seleccione archivo'
            }
          });
          
          if (file) {
            setCargando(true);
            const data = new FormData()
            data.append('file', file);
            data.append('id', t.Id);    
            data.append('mat', "0");    
            let url = `${edoGlobal.urlws}/trabajo/subirArchivo`;
            const resp = await fetch(url,{
                method: 'POST', // or 'PUT'
                body: data // data can be `string` or {object}!
              });
            const res = await resp.text();
            setCargando(false);
            if(res == "ok"){
                cargarTrabajos();
            }
            else
               Swal.fire('Atencion',res,'success');    
          }        
    }
    function verArchivo(t){
        let url = `${edoGlobal.urlws}/content/trabajos/${edoGlobal.usuario.Institucion}/${t.Archivo}`;
        window.open(url, '', '');    
    }
    function editarAlumno(a,i){
        //setMatricula(a.Id);
       // setIndice(i)
       // setOpcion(10);
    }
    async function eliminar(t){
        if(!props.grupo.Abierto){
            Swal.fire('Atencion','el grupo esta cerrado','error');    
            return;
        } 
        let url = `${edoGlobal.urlws}/trabajo/eliminiar/${t.Id}`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        trabajos =[];
        setTrabajos([]);
        setCargando(false);
        cargarTrabajos();
    }
    async function calificar(t){
        if(!props.grupo.Abierto){
            Swal.fire('Atencion','el grupo esta cerrado','error');    
            return;
        } 
        setNombre(t.Nombre);
        setTrabajo(t);
        let url = `${edoGlobal.urlws}/trabajo/alumnos/${t.Id}`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.json();
        setCargando(false);
        setAlumnos(res);
        setCalificando(true);
    }
    async function guardar(){
        const dato = {
            Id : trabajo.Id,
            Grupo : props.grupo.Id,
            Fecha : fechaCap,
            FechaMax : fechaMax,
            Nombre : nombre,
            Paginas : paginas,
            Unidad : unidad,
            RA : ra,
            Puntos : puntos,
            Individual : individual
        }
        let url = `${edoGlobal.urlws}/trabajo/wsguardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            trabajos = [];
            cargarTrabajos();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    function bajar(o){
        if(enPestana){
            let url = `${edoGlobal.urlws}/content/trabajos/${edoGlobal.institucion}/${o.Archivo}`;
            window.open(url, '', '');
            return;
        }
        setUrlArchivo(edoGlobal.urlws + "/content/trabajos/" + edoGlobal.institucion + "/" + o.Archivo);
    }
    const estiloA = { display: calificando ? 'none' : 'inline' };
    const estiloB = { display: !calificando ? 'none' : 'inline' };
    let ras = [];
    if(unidad != 0){
        for(let ix = 0; ix < props.unidades.length; ix++)
           if(props.unidades[ix].Id == unidad)
              ras = props.unidades[ix].Resultados;
    }
    return(
        <div>
            <div style={estiloA}>
                <Row>
                    <div className='col'>
                        <table>
                            <tr style={{ verticalAlign: 'center' }}>
                                <td><span>Actividades</span></td>
                                <td><span>&nbsp;&nbsp;</span>
                                    {
                                    //props.grupo.Abierto && <img onClick={() => { editar({ Id: 0, Fecha: fechaCap, Nombre: '', Paginas:'', Individual: true }) }} src="imagenes/add.png" alt="nueva actividad" title="nueva actividad" />
                                    }
                                    {props.grupo.Abierto && <Button onClick={actualizarTrabajos} color="secondary" size="sm" outline><BiRefresh size={20} color='maroon' title="actualizar lista de actividades" /></Button>}
                                </td>
                            </tr>
                        </table>
                        <Table size="sm" hover striped>
                            <thead>
                                <tr>
                                    <th className='celdaFecha'>Dia</th>
                                    <th>Nombre</th>
                                    <th className='celdaBoton'>Puntos</th>
                                    <th className='celdaBoton'>Individual</th>
                                    <th className='celdaBoton'></th>
                                    <th className='celdaBoton'></th>
                                    <th className='celdaBoton'></th>
                                    <th className='celdaBoton'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {trabajos.map((o, i) => {
                                    return (
                                        <tr key={i} >
                                            <td>
                                                <b>{o.Fecha}</b><br/>
                                                <Badge style={{color:'white'}}><h6>&nbsp;&nbsp;{i+1}&nbsp;&nbsp;</h6></Badge>
                                            </td>
                                            <td>
                                                {o.NomUni == "" ? null :
                                                    <div>
                                                        <span><span className='rojo'>&nbsp;&#9654;&nbsp;</span>{o.NomUni};</span><br/>
                                                        <span className='rojo'>{o.NomTema}</span>
                                                    </div>
                                                }
                                                <b>{o.Nombre}</b>
                                                {o.Paginas == "" ? null :<div><span>Enlaces:</span><br/>
                                                    {o.Paginas.split(',').map((o,i) => {
                                                        let link = "http://" + o.trim();
                                                        return <span><Badge><a style={{color:'white', fontSize:12}} target="_blank" href={link}>{o.trim()}</a></Badge>&nbsp;</span>
                                                    })}
                                                </div>}
                                            </td>
                                            <td>{o.Puntos}</td>
                                            <td><input type="checkbox" checked={o.Individual} /></td>
                                            <td>
                                                {/*
                                                <img onClick={() => editar(o)} src="/imagenes/edit.png" alt="editar" title="editar" />
                                                */}
                                            </td>
                                            <td><IoIosSearch color='maroon' size={20} onClick={() => subir(o)}  title="subir archivo" /></td>
                                            <td>{o.Puntos > 0 && <BsFillFileEarmarkDiffFill size={20} color='maroon' onClick={() => calificar(o)}  title="calificar" />}</td>
                                            <td>{o.ConArchivo != "" && <BiSolidFilePdf color='maroon' size={20} onClick={()=> verArchivo(o)}  title="ver archivo" />}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <Modal isOpen={capturando} toggle={() => setCapturando(false)}>
                        <ModalHeader>
                            {trabajo.Id == 0 ? <span>Nueva actividad</span> : <span>Editar actividad</span>}
                        </ModalHeader>
                        <Container>
                            <Row>
                                <div className='col-4'>
                                    <span>Fecha</span>
                                    <Input size="sm" type="date" value={fechaCap} onChange={(e) => setFechaCap(e.target.value)} />
                                </div>
                                <div className='col-4'>
                                    <span>Fecha Entrega</span>
                                    <Input size="sm" type="date" value={fechaMax} onChange={(e) => setFechaMax(e.target.value)} />
                                </div>
                                <div className='col-4'>
                                    <span>Puntos</span>
                                    <Input size="sm" type="text" value={puntos} />
                                </div>
                            </Row>
                            <div style={{height:5}}></div>
                            <Row>
                                <div className='col'>
                                    <span>Actividad</span>
                                    <Input type="textarea" style={{height:150}} value={nombre}  />
                                </div>
                            </Row>
                            <div style={{height:5}}></div>
                            <Row>
                                <div className='col'>
                                    <span>Enlaces</span>
                                    <Input type="textarea" style={{height:150}} value={paginas}  />
                                </div>
                            </Row>
                            <div style={{height:5}}></div>
                            <Row>
                                <div className='col-1'></div>
                                <div className='col'>
                                    <Input type="checkbox" checked={individual}  />
                                    <span>Individual</span>
                                </div>
                            </Row>
                            <br />
                            <Row>
                                <div className='col-2'></div>
                                <div className='col'>
                                    <Button size="sm" color="secondary" outline onClick={() => guardar()}>Guardar</Button>
                                </div>
                            </Row>
                        </Container>
                        <br />
                    </Modal>
                </Row>
            </div>
            <div style={estiloB}>
                <h6>{nombre}                 <Button size="sm" color="secondary" outline onClick={() => setCalificando(false)}>Regresar</Button></h6>
                <div style={{ height: 450, overflow: 'auto' }}>
                    <Table size="sm" hover striped>
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Matricula</th>
                                <th style={{ width: 300 }}>Nombre</th>
                                <th style={{ width: 100 }}>Equipo</th>
                                <th style={{ width: 80 }}>Calif.</th>
                                <th>Comentario</th>
                                <th className='celdaBoton'></th>
                                <th className='celdaBoton'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {alumnos.map((o, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{o.Alumno}</td>
                                        <td>{o.Nombre}</td>
                                        <td>{o.NEq}</td>
                                        <td>{o.Calif}</td>
                                        <td>{o.Comentario}</td>
                                        <td><BsFillFileEarmarkDiffFill size={20} color='maroon' onClick={() => calificarAlumno(i)} title="calificar" /></td>
                                        <td>{o.Archivo !== "" && <BiSolidFilePdf color='maroon' size={20} onClick={() => bajar(o, i)} title="ver archivo" />}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                <Button size="sm" color="secondary" outline onClick={() => setCalificando(false)}>Regresar</Button>
                <Modal isOpen={capturandoCalif} toggle={() => setCapturandoCalif(false)} size="xl">
                    <ModalHeader>
                        <span>{trabajo.Nombre}</span>
                    </ModalHeader>
                    <Container>
                        <Row>
                            <div className='col-4'>
                                <h5><Badge style={{ color: 'white' }}>{alumnos[indice].Alumno}  -   {alumnos[indice].Nombre}</Badge></h5>
                                <br />
                                <Row>
                                    <div className='col-1'><span>Puntos</span></div>
                                    <div className='col-2'><Input id={"txtCalif" + props.grupo.Id} size="sm" type="text" value={calif} onChange={e => setCalif(e.target.value)} onKeyDown={keyDown} onKeyPress={keyPress} /></div>
                                </Row>
                                <br/>
                                <Row>
                                <div className='col' style={{height:40}}>
                                        {alumnos[indice].Archivo !== "" &&
                                            <div>
                                                <Button size="sm" color="secondary" outline onClick={() => bajar(alumnos[indice], indice)}><BiSolidFilePdf color='maroon' size={20} title="ver archivo" />&nbsp;ver archivo</Button>
                                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <Input type="checkbox" checked={enPestana} onChange={(e) => setEnPestana(e.target.checked) }  /><span>Ver en una pestaña</span>
                                            </div>}
                                    </div>
                                </Row>
                                <br/>
                                <span>texto del alumno</span>
                                <Card style={{padding:4, height:100}}>
                                    {alumnos[indice].Texto}
                                </Card>
                                <Row>
                                    <div className='col'>
                                        <span>Comentario</span>
                                        <Input type="textarea" id={"txtComenta" + props.grupo.Id} value={comentario} onChange={(e) => setComentario(e.target.value)} onKeyDown={keyDown} style={{height:200}} />
                                    </div>
                                </Row>
                                <br />
                                <Row>
                                    <div className='col-2'></div>
                                    <div className='col'>
                                        <Button size="sm" color="secondary" outline onClick={() => anterior()}>&lt;</Button>
                                        &nbsp;&nbsp;
                                        <Button size="sm" color="secondary" outline onClick={() => guardarCalifTrabajo()}>Guardar</Button>
                                        &nbsp;&nbsp;
                                        <Button size="sm" color="secondary" outline onClick={() => siguiente()}>&gt;</Button>
                                    </div>
                                </Row>
                            </div>
                            <div className='col'>
                                {urlArchivo != "" && <iframe style={{width:'100%', height:600}} src={urlArchivo}></iframe> }
                            </div>
                        </Row>
                    </Container>
                    <br />
                </Modal>
                <Modal isOpen={capturandoCalifEq} toggle={() => setCapturandoCalifEq(false)}>
                    <ModalHeader>
                        <span>{trabajo.Nombre}</span>
                    </ModalHeader>
                    <Container>
                        <h5>Equipo <Badge style={{color:'white'}}>{props.equipos.length == 0 ? "": props.equipos[indiceEquipo].Nombre}</Badge></h5>
                        <br/>
                        {alumnos.filter((e)=> e.Eq == equipo).map((a,i) => <span key={i}><span><span className='rojo'>  &#10151;</span>{a.Nombre}</span></span>)}
                        <br/>
                        <Row>
                            <div className='col-1'><span>Puntos</span></div>
                            <div className='col-2'><Input id={"txtCalif" + props.grupo.Id} size="sm" type="text" value={calif} onChange={e => setCalif(e.target.value)} onKeyDown={keyDown} onKeyPress={keyPress}/></div>
                            <div className='col-2'></div>                            
                            <div className='col'>
                            {alumnos[indice].Archivo !== "" && <Button size="sm" color="secondary" outline onClick={() => bajar(alumnos[indice], indice)}><BiSolidFilePdf color='maroon' size={20} title="ver archivo" />&nbsp;ver archivo</Button>}
                            </div>
                        </Row>
                        <Row>
                            <div className='col'>
                                <span>Comentario</span> 
                                <Input type="textarea" id={"txtComenta" + props.grupo.Id} value={comentario} onChange={(e) => setComentario(e.target.value)} onKeyDown={keyDown}/>
                            </div>
                        </Row>
                        <br />
                        <Row>
                            <div className='col-2'></div>
                            <div className='col'>
                                <Button size="sm" color="secondary" outline onClick={() => equipoAnterior()}>&lt;</Button>
                                &nbsp;&nbsp;
                                <Button size="sm" color="secondary" outline onClick={() => guardarCalifTrabajoEq()}>Guardar</Button>
                                &nbsp;&nbsp;
                                <Button size="sm" color="secondary" outline onClick={() => equipoSiguiente()}>&gt;</Button>
                            </div>
                        </Row>
                    </Container>
                    <br />
                </Modal>
           </div>
        </div>
    )
}