import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Input, Row, Table, Button, Modal, ModalHeader, ModalBody, CardText, Container } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Grupos } from './Grupos';
import { BiMailSend, BiSolidFilePdf } from 'react-icons/bi';

export const GrupoAsistencia = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [fecha,setFecha] = useState("");
    let [fechaCap,setFechaCap] = useState(new Date().yyyymmdd('-'));
    let [lista,setLista] = useState([]);
    let [asistencias,setAsistencias] = useState([]);
    let [capturando,setCapturando] = useState(false);
    let [cambios,setCambios] = useState(0);

    useEffect(() => {
        (async () => {
            cargarAsistencias();
        })();
    }, []);

    function setCargando(v){
        props.setCargando(v);
    }
    async function cargarAsistencias(){
        if(asistencias.length !== 0)
           return;
        setCapturando(false);
        setCargando(true);
        let url = `${edoGlobal.urlws}/grupo/asistencias/${props.grupo.Id}`;
        let resp = await fetch(url);
        const a = await resp.json();
        setCargando(false);
        setAsistencias(a);
    }
    async function cargarLista(fec){
        //let fec = asistencias[i].D;
        setCargando(true);
        let url = `${edoGlobal.urlws}/grupo/asistencia/${props.grupo.Id}?fecha=${fec}`;
        let resp = await fetch(url);
        const a = await resp.json();
        setCargando(false);
        setFecha(fec);
        setLista(a);
    }
    function asistenciasPDF(){
        let url = `${edoGlobal.urlws}/grupo/imprimirAsistencia/${props.grupo.Id}`;
        window.open(url, '', '');    
    }
    async function asistenciasEmail(){
        const email = edoGlobal.usuario.Email;
        const {value: emailCap} = await Swal.fire({
            title: 'Enviar archivo PDF por email',
            input: 'email',
            inputValue: email,
            inputPlaceholder: 'Direccion de email'
          })
          
          if (emailCap) {
            let url = `${edoGlobal.urlws}/grupo/enviarAsistencia?grupo=${props.grupo.Id}&email=${emailCap}`;
            setCargando(true);
            const req = await fetch(url, {
                method: 'POST' // or 'PUT'
              })
            const res = await req.text();
            setCargando(false);
            if(res !== "ok"){
                Swal.fire('Atencion',res,'error');
                return res;
            }
            Swal.fire('Atencion','Se envio el email.','success');
        }
    }
    async function capturarLista(){
        let url = `${edoGlobal.urlws}/grupo/validarFechaLista/${props.grupo.Id}?fec=${fechaCap}`;
        setCargando(true);
        const req = await fetch(url);
        const res = await req.text();
        setCargando(false);
        if(res !== "ok"){
            Swal.fire('Atencion',res,'error');
            //setCapturando(false);
            //setLista([]);
            //return;
        }
        cargarLista(fechaCap);
        setCapturando(true);
    }
    function actualizaAsistencia(i,v){
        lista[i].A = v;
        setCambios(cambios + 1);
    }
    function actualizaAsistenciaB(i,v){
        lista[i].V = v;
        setCambios(cambios + 1);
    }
    async function guardarAsistencia() {
        var a = [];
        for (let i = 0; i < lista.length; i++)
           a.push({Id : lista[i].Id, A: lista[i].V});
        const dato = {
            grupo : props.grupo.Id,
            fecha : fechaCap,
            ubicacion:'',
            s: JSON.stringify(a)
        }
        let url = `${edoGlobal.urlws}/grupo/wsguardarAsistencia`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            asistencias = [];
            cargarAsistencias();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    return(
        <div>
            <Row>
                <div className='col-4'>
                    <table>
                        <tr style={{ verticalAlign: 'center' }}>
                            <td className='celdaBoton'><BiSolidFilePdf size={24} color='maroon' title="ver PDF" onClick={asistenciasPDF} /></td>
                            <td><span>&nbsp;&nbsp;</span></td>
                            <td className='celdaBoton'><BiMailSend size={24} color='maroon' onClick={asistenciasEmail} title="enviar por Email" /></td>
                            <td><span>&nbsp;&nbsp;</span></td>
                            <td><span>&nbsp;&nbsp;</span></td>
                            <td><Input size="sm" type="date" value={fechaCap} onChange={(e)=>setFechaCap(e.target.value)} /></td>
                            <td>{props.grupo.Abierto && <Button onClick={capturarLista} size="sm" color="secondary" outline>Capturar</Button>} </td>
                        </tr>
                    </table>
                    <Table size="sm" hover striped>
                        <thead>
                            <tr>
                                <th>Dia</th>
                                <th>A</th>
                                <th>F</th>
                                <th>R</th>
                                <th>J</th>
                            </tr>
                        </thead>
                        <tbody>
                            {asistencias.map((o, i) => {
                                let f = "" + o.D;
                                f = f.substring(0,4) + '-' + f.substring(4,6) + '-' + f.substring(6,8);
                                return (
                                    <tr key={i} className="cardAlumno" onClick={() => {
                                        cargarLista(o.D);                                        
                                        setFechaCap(f);
                                        setCapturando(false);
                                        }}
                                    >
                                        <td><span className='rojo'>{f}</span></td>
                                        <td>{o.A}</td>
                                        <td>{o.F}</td>
                                        <td>{o.R}</td>
                                        <td>{o.J}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                <div className='col'>
                    { capturando ? <Button size="sm" color="secondary" outline onClick={guardarAsistencia}>Guardar</Button> : null }
                    <Table size="sm" hover striped>
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Matricula</th>
                                <th>Nombre</th>
                                <th style={{width:100, textAlign:'center'}}>Asistio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lista.map((o, i) => {
                                return (
                                    <tr key={i} className="cardAlumno">
                                        <td><span className='rojo'>{o.Id}</span></td>
                                        <td onClick={(e) => actualizaAsistencia(i,!o.A) }>{o.Nombre}</td>
                                        <td><Asistencia a={o.V} indice={i} actualizar={actualizaAsistenciaB} capturando={capturando} /></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </Row>
        </div>
    )
}
const Asistencia = (props) => {
    function cambiar(){
        if(!props.capturando)
            return;
        if(props.a == "S")
            props.actualizar(props.indice, "N");
        else if(props.a == "N")
            props.actualizar(props.indice, "R");
        else if(props.a == "R")
            props.actualizar(props.indice, "J");
        else if(props.a == "J")
            props.actualizar(props.indice, "S");
    }
    let color1 = 'maroon';
    let color2 = 'maroon';
    let color3 = 'white';
    let dato = "Si";
    if(props.a == "N"){
        dato = "No";
        color1 = 'white';    
        color2 = 'black';
        color3 = 'maroon';
    }
    else if(props.a == "R")
        dato = "Retardo";
    else if(props.a == "J")
        dato = "Justificada";
    let estilo = { width:90, color : color1, borderColor:color2, backgroundColor:color3};
    return (
        <Button size="sm" style={estilo} outline onClick={cambiar}>{dato}</Button>
    )
}