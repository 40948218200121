import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Input, Row, Table, Button, Spinner, Modal, ModalHeader, ModalBody, CardText, Container } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Grupos } from './Grupos';
import { ProgramaCompleto } from './ProgramaCompleto';
import { GrupoSesiones } from './GrupoSesiones';
import { BiCheck, BiSolidFilePdf } from 'react-icons/bi';
import { IoIosSearch, IoMdCreate } from 'react-icons/io';
import { MdChecklistRtl, MdEditCalendar } from 'react-icons/md';
import { FaTasks } from 'react-icons/fa';
import { BsFillFileEarmarkDiffFill } from 'react-icons/bs';
import { TiPlus, TiThMenu } from 'react-icons/ti';

export const GrupoAlumno = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [alumno,setAlumno] = useState({});
    let [datos,setDatos] = useState([]);
    let [trabajos,setTrabajos] = useState([]);
    let [unidades, setUnidades] = useState([]);
    let [fechaCap,setFechaCap] = useState(new Date().yyyymmdd('-'));
    let [matActual,setMatActual] = useState("");
    let [texto,setTexto] = useState("");
    let [califTrab, setCalifTrab] = useState("");
    let [textoAlumno,setTextoAlumno] = useState("");
    let [cargando,setCargando] = useState(false);
    let [iniciando,setIniciando] = useState(false);
    let [periodo,setPeriodo] = useState("");
    let [archivo,setArchivo] = useState("");
    let [anotaciones,setAnotaciones] = useState([]);
    let [anotacion,setAnotacion] = useState({Id:0, Fecha:'', Texto:'', Alumno:''});
    let [capturando,setCapturando] = useState(false);
    let [capturandoTextoAlumno,setCapturandoTextoAlumno] = useState(false);
    let [editarEquipo,setEditarEquipo] = useState(false);
    let [equipo,setEquipo] = useState(0);
    let [idTrabajo,setIdTrabajo] = useState(0);
    let [nomEquipo,setNomEquipo] = useState("");
    let [cambios,setCambios] = useState(0);
    let [indice,setIndice] = useState(0);
    let [calif1,setCalif1] = useState("");
    let [calif2,setCalif2] = useState("");
    let [calif3,setCalif3] = useState("");
    let [califOrd,setCalifOrd] = useState("");
    let [califFin,setCalifFin] = useState("");
    let [ejes,setEjes] = useState([]);
    let [opcion, setOpcion] = useState(1);

    useEffect(() => {
        (async () => {
            matActual = props.matricula;
            setMatActual(props.matricula);
            if(props.alumnos != undefined)
                for (let i = 0; i < props.alumnos.length; i++)
                    if (props.alumnos[i].Id == props.matricula){
                        indice = i;
                        setIndice(i);
                    }
            if(props.matricula != null && props.matricula != ""){
                setEquipo(props.equipo);
                cargarDatos();
            }
        })();
    }, [props.matricula]);  

    async function cargarDatos(){
        setCapturando(false);
        setCapturandoTextoAlumno(false);
        setCargando(true);
        setIniciando(true);
        setTrabajos([]);
        setDatos([]);
        let url = `${edoGlobal.urlws}/alumno/cargar/${matActual}?inst=${edoGlobal.institucion}&grupo=${props.grupo.Id}`;
        let resp = await fetch(url);
        const alumno = await resp.json();
        url = `${edoGlobal.urlws}/alumno/resumenGrupo/${matActual}?grupo=${props.grupo.Id}`;
        resp = await fetch(url);
        const d = await resp.json();
        url = `${edoGlobal.urlws}/alumno/resumenGrupoTrab/${matActual}?grupo=${props.grupo.Id}`;
        resp = await fetch(url);
        const t = await resp.json();
        url = `${edoGlobal.urlws}/grupo/cargar/${props.grupo.Id}`;
        resp = await fetch(url);
        const g = await resp.json();
        url = `${edoGlobal.urlws}/unidad/unidades/${g.Materia}?version=${g.Version}`;
        resp = await fetch(url);
        const u = await resp.json();
        url = `${edoGlobal.urlws}/programaIndicativo/ejes?pi=${props.grupo.Programa}`;
        resp = await fetch(url);
        const ej = await resp.json();

        setAlumno(alumno);
        setUnidades(u);
        setEditarEquipo(false);
        setPeriodo(g.Periodo);
        setDatos(d);
        setTrabajos(t);
        setEjes(ej);
        setEquipo(alumno.Equipo);
        if(d.length > 0){
            let i = d.length - 1;
            setCalif1(d[i].Dato1);
            setCalif2(d[i].Dato2);
            setCalif3(d[i].Dato3);
            setCalifOrd(d[i].S);
            setCalifFin(d[i].T);
        }
        setIniciando(false);
        setCargando(false);
    }
    async function anterior(){
        if(indice == 0)
            return;
        indice--;
        setIndice(indice);
        matActual = props.alumnos[indice].Id;
        setMatActual(matActual);
        cargarDatos();
    }
    async function siguiente(){
        if(indice == props.alumnos.length -1)
            return;
        indice++;
        setIndice(indice);
        matActual = props.alumnos[indice].Id;
        setMatActual(matActual);
        cargarDatos();
    }
    async function editar(t){
        if(t.Id != 0){
            setCargando(true);
            let url = `${edoGlobal.urlws}/anotacion/cargar/${t.Id}`;
            let resp = await fetch(url);
            t = await resp.json();
            setCargando(false);
        }
        setAnotacion(t);
        setFechaCap(t.F);
        setTexto(t.Texto);
        setCapturando(true);
    }
    async function editarTrabajo(t){
        setCargando(true);
        setIdTrabajo(t.Id);
        let url = `${edoGlobal.urlws}/trabajo/trabajoAlumno/${t.Id}?alumno=${matActual}`;
        let resp = await fetch(url);
        t = await resp.json();
        setCargando(false);
        setTextoAlumno(edoGlobal.usuario.Tipo === "A" ? t.Texto : t.Comentario);
        setArchivo(t.Archivo);
        setCalifTrab(t.Calif);
        setCapturandoTextoAlumno(true);
    }
    async function guardarEquipo(){
        let url = `${edoGlobal.urlws}/alumno/asignarEquipo/${matActual}?grupo=${props.grupo.Id}&equipo=${equipo}`;
        setCargando(true);
        const resp = await fetch(url,{
            method: 'POST'
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            cargarDatos();
            props.cambiarEquipo(equipo,indice);
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    async function guardar(){
        const dato = {
            Id : anotacion.Id,
            Grupo : props.grupo.Id,
            Fecha : fechaCap,
            Alumno : matActual,
            Texto :texto
        }
        let url = `${edoGlobal.urlws}/anotacion/wsguardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            cargarDatos();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    async function guardarTextoAlumno(){
        let url = `${edoGlobal.urlws}/trabajo/wsGuardarTextoAlumno/${idTrabajo}?alumno=${matActual}`;
        setCargando(true);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: textoAlumno // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            cargarDatos();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    async function guardarCalifTrabajo(){
        let url = `${edoGlobal.urlws}/trabajo/wsGuardarCalifAlumno/${idTrabajo}?alumno=${matActual}&calif=${califTrab}`;
        setCargando(true);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: textoAlumno // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            cargarDatos();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    function bajar(a){
        let url = `${edoGlobal.urlws}/content/trabajos/${edoGlobal.institucion}/${a}`;
        window.open(url, '', '');    
    }
    async function subir(){
        const { value: file } = await Swal.fire({
            title: 'Subir archivo',
            input: 'file',
            inputAttributes: {
              accept: '*/*',
              'aria-label': 'Seleccione archivo'
            }
          });
          
          if (file) {
            setCargando(true);
            const data = new FormData()
            data.append('file', file);
            data.append('id', idTrabajo);    
            data.append('mat', matActual);    
            let url = `${edoGlobal.urlws}/trabajo/subirArchivo`;
            const resp = await fetch(url,{
                method: 'POST', // or 'PUT'
                body: data // data can be `string` or {object}!
              });
            const res = await resp.text();
            setCargando(false);
            Swal.fire('Atencion',res,'success');    
          }        
    }
    function verArchivoTrabajo(t){
        let url = `${edoGlobal.urlws}/content/trabajos/${edoGlobal.usuario.Institucion}/${t.V}`;
        window.open(url, '', '');    
    }
    function verArchivo(d){
        let url = `${edoGlobal.urlws}/content/documentos/${edoGlobal.usuario.Institucion}/${periodo}/${d.Dato2}`;
        window.open(url, '', '');    
    }
    function imagen(tipo){
        if(tipo === "pdf")
           return "pdf.png"
        if(tipo === "zip" || tipo === "rar")
           return "zip.png"
        if(tipo === "csv" || tipo.substring(0,4) === "xls")
           return "excel.png"
        if(tipo.substring(0,4) === "doc")
           return "word.png"
        if(tipo.substring(0,4) === "ppt")
           return "ppt.png";
        if(tipo.substring(0,4) === "htm" || tipo == "xml")
           return "xml.png";
        return "tabla.png"
    }
    const estiloResumen = { display: opcion !== 1 ? 'none' : 'inline' };
    const estiloAvance = { display: opcion !== 2 ? 'none' : 'inline' };
    const estiloEjes = { display: opcion !== 3 ? 'none' : 'inline' };

    return(
        <div>
            {cargando || iniciando ?
                <div className="loader">
                    <Spinner animation="border" className='rojo' >&nbsp;</Spinner>
                </div>
                :
                null
            }
            {edoGlobal.usuario.Tipo === "P" && <table>
                <tr style={{valign:'top'}}>
                    <td className="celdaBoton">
                        { indice == 0 ? null : <Button className='botonLince' onClick={anterior} size="sm" color="secondary" outline><span>&#9664;</span></Button> }
                    </td>
                    <td className="celdaBoton">
                        { indice == props.alumnos.length -1 ? null : <Button className='botonLince' onClick={siguiente} size="sm" color="secondary" outline><span>&#9654;</span></Button> }
                    </td>
                    <td className='celdaNombre'>
                        <b><span className='rojo'>{alumno.Id}</span> - {alumno.Nombre} {alumno.ApePat} {alumno.ApeMat}</b>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td>Equipo</td>
                    <td>
                        <Input size="sm" type="select" value={equipo} disabled={!editarEquipo} onChange={(e)=>setEquipo(e.target.value)} >
                            <option value={0}>No asignado</option>
                            {props.equipos.map((o,i)=>
                                <option key={i} value={o.Id}>{o.Nombre}</option> 
                            )}
                        </Input>
                    </td>
                    <td>
                        {editarEquipo ?
                    <Button className='botonLince' onClick={guardarEquipo} size="sm" color="secondary" outline><BiCheck size={20} color='maroon' title="guardar" /></Button>
                    :
                    <Button className='botonLince' onClick={()=> setEditarEquipo(true)} size="sm" color="secondary" outline><IoMdCreate size={20} color='maroon' title="agregar" /></Button>
                        }
                    </td>
                    <td>
                        <Button className='botonLince' onClick={props.regresar} size="sm" color="secondary" outline>regresar</Button>                        
                    </td>
                </tr>
            </table>
            }
            <table>
                <tr>
                    <td style={{padding:5}} onClick={() => setOpcion(1)}>
                        <Button size="sm" color="secondary" className='botonLince' outline title="resumen">
                            <MdEditCalendar size={20} color='maroon'/>
                            <br />
                            <span>resumen</span>
                        </Button>
                    </td>
                    <td style={{padding:5}} onClick={() => setOpcion(2)}>
                        <Button size="sm" color="secondary" className='botonLince' outline title="temas">
                            <MdChecklistRtl size={20} color='maroon'/>
                            <br />
                            <span>avance diario</span>
                        </Button>
                    </td>
                    <td style={{padding:5}} onClick={() => setOpcion(3)}>
                        <Button size="sm" color="secondary" outline className='botonLince' title="Programa indicativo">
                            <FaTasks size={20} color='maroon'/>
                            <br />
                            <span>programa indicativo</span>
                        </Button>
                    </td>
                </tr>
            </table>
            <div style={estiloResumen}>
                <Row>
                    <div className='col-4'>
                        <b className='rojo'>Calificaciones</b>
                        <Table striped hover>
                            <tr>
                                <td>1er P. : <b>{calif1}</b></td>
                                <td>2do P. : <b>{calif2}</b></td>
                                <td>3er P. : <b>{calif3}</b></td>
                                <td>Ord. : <b>{califOrd}</b></td>
                                <td>Final : <b>{califFin}</b></td>
                            </tr>
                        </Table>
                    </div>
                </Row>
                <Row>
                    <div className='col-2'>
                        <b className='rojo'>Asistencias</b>
                        <Table size="sm" hover striped>
                            <thead>
                                <tr>
                                    <th>Dia</th>
                                    <th>Asistio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {datos.filter(d => d.Tipo === "A").map((o, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{o.Dato1}</td>
                                            <td>{o.Dato2}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className='col-6'>
                        <b className='rojo'>Actividades</b>
                        <Table size="sm" hover striped>
                            <thead>
                                <tr>
                                    <th>Actividad</th>
                                    <th>Entregado</th>
                                    <th>Calif./Comentario</th>
                                    <th className='celdaBoton'></th>
                                    <th className='celdaBoton'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {trabajos.map((o, i) => {
                                        return (
                                        <tr key={i}>
                                            <td>
                                                <b className='rojo'>{o.Fe}</b> - <b>{o.Dato4}</b><br/>
                                                <span>{o.Dato1}</span>
                                                {o.P == "" ? null : <div><span>Enlaces:</span><br /><span>{o.P}</span></div>}
                                            </td>
                                            <td><input type="checkbox" checked={o.Dato2 !== "NO"} /></td>
                                            <td>
                                                {edoGlobal.usuario.Tipo === "A" && o.Editar && <IoMdCreate size={20} color='maroon' onClick={() => editarTrabajo(o)} title="editar" />}
                                                {edoGlobal.usuario.Tipo === "P" && <BsFillFileEarmarkDiffFill size={20} color='maroon' onClick={() => editarTrabajo(o)} title="revisar" />}
                                                <span>&nbsp;&nbsp;</span>
                                                <span className={o.Dato3 == "Pendiente" ? "" : "rojo"}>{o.Dato3}</span>
                                            </td>
                                            <td>
                                                {o.S !== "" && <BiSolidFilePdf color='maroon' size={20} title="ver material de apoyo" onClick={() => bajar(o.S)} />}
                                            </td>
                                            <td>{o.V != "" && <BiSolidFilePdf color='maroon' size={20} onClick={() => verArchivoTrabajo(o)}  title="ver archivo enviado" />}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <b className='rojo'>Documentos</b>
                        <Table size="sm" hover striped>
                            <thead>
                                <tr>
                                    <th>Texto</th>
                                    <th className='celdaFecha'>Tipo</th>
                                    <th className='celdaBoton'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {datos.filter(d => d.Tipo === "T").map((o, i) => {
                                    const logo = "/imagenes/" + imagen(o.Dato3);
                                    return (
                                        <tr key={i} >
                                            <td>{o.Dato1}</td>
                                            <td>{o.Dato3}</td>
                                            <td>{o.Dato2 != "" && <img onClick={() => verArchivo(o)} src={logo} alt="ver" title="ver archivo" />}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className='col-4'>
                        {edoGlobal.usuario.Tipo === "P" && <div>
                            <b className='rojo'>Anotaciones</b>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button onClick={() => { editar({ Id: 0, Fecha: fechaCap, Texto: '', Alumno: '' }) }} size="sm" color="secondary" outline><TiPlus size={20} color='maroon' title="agregar" /></Button>
                            <Table size="sm" hover striped>
                                <thead>
                                    <tr>
                                        <th>Dia</th>
                                        <th>Grupal</th>
                                        <th>Texto</th>
                                        {edoGlobal.usuario.Tipo === "P" && <th className='celdaBoton'></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {datos.filter(d => d.Tipo === "D").map((o, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{o.Dato1}</td>
                                                <td><input type="checkbox" checked={o.Dato3 === "G"} /></td>
                                                <td>{o.Dato2}</td>
                                                {edoGlobal.usuario.Tipo === "P" && <td>{o.Dato3 === "G" ? null : <IoMdCreate size={20} color='maroon' onClick={() => editar(o)} title="editar" />}</td>}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>}
                        {edoGlobal.usuario.Tipo === "A" && <div>
                            <b className='rojo'>Anuncios</b>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Table size="sm" hover striped>
                                <thead>
                                    <tr>
                                        <th>Anuncio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datos.filter(d => d.Tipo === "C").map((o, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{o.Dato1}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>}
                    </div>
                </Row>
            </div>
            <div style={estiloAvance}>
                <h5 className='rojo'>Avance diario</h5>
                <GrupoSesiones grupo={props.grupo} setCargando={setCargando} consulta={true}/>
            </div>
            <div style={estiloEjes}>
                <h5 className='rojo'>Programa indicativo</h5>
                <ProgramaCompleto ejes={ejes} />
            </div>

            <Modal isOpen={capturando} toggle={()=>setCapturando(false)}>
                <ModalHeader>
                    {anotacion.Id == 0 ? <span>Nueva anotacion</span> : <span>Editar anotacion</span>}
                </ModalHeader>
                <Container>
                    <Row>
                        <div className='col-4'>
                            <span>Fecha</span>
                            <Input size="sm" type="date" value={fechaCap} onChange={(e)=>setFechaCap(e.target.value)} />
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <span>Tema</span>
                            <Input type="textarea" value={texto} onChange={(e)=>setTexto(e.target.value)} />
                        </div>
                    </Row>
                    <br/>
                    <Row>
                        <div className='col-2'></div>
                        <div className='col'>
                            <Button size="sm" color="secondary" outline onClick={()=>guardar()}>Guardar</Button>
                        </div>
                    </Row>
                </Container>
                <br/>
            </Modal>
            <Modal isOpen={capturandoTextoAlumno} toggle={()=>setCapturandoTextoAlumno(false)}>
                <ModalHeader>
                    {edoGlobal.usuario.Tipo === "A" ?
                    <div>
                    <span>Respuesta &nbsp;&nbsp;</span>
                    <Button outline color="secondary" size="sm" onClick={subir} ><IoIosSearch color='maroon' size={20}  title="subir archivo" />Subir archivo</Button>
                    </div>
                    :
                    <div>
                    <span>Calificacion &nbsp;&nbsp;</span>
                    <Input type="text" size="sm" value={califTrab} onChange={ e => setCalifTrab(e.target.value)}/>
                    </div>
                        }
                </ModalHeader>
                <Container>
                    <br/>
                    <Row>
                        <div className='col'>
                            <Input type="textarea" value={textoAlumno} onChange={(e)=>setTextoAlumno(e.target.value)} />
                        </div>
                    </Row>
                    <br/>
                    <Row>
                        <div className='col-2'></div>
                        <div className='col'>
                            <Button size="sm" color="secondary" outline onClick={()=> edoGlobal.usuario.Tipo === "A" ? guardarTextoAlumno(): guardarCalifTrabajo()}>Guardar</Button>
                        </div>
                    </Row>
                </Container>
                <br/>
            </Modal>
        </div>
    )
}
function Unidad(props){
    return(
        <li>
            <h6 className='rojo'> {props.unidad.Orden} - {props.unidad.Texto}</h6>
            <ul>
                {props.unidad.Resultados.map((o,i)=><ResultadoAprendizaje key={i} resultado={o} />)}
            </ul>
        </li> 
    )
}
function ResultadoAprendizaje(props){
    return(
        <li>
            <h6>{props.resultado.Orden} - {props.resultado.Texto}</h6>
            {props.resultado.Indicadores.length > 0 && <div>
                <span>Indicadores</span>
                <ul>
                    {props.resultado.Indicadores.map((o,i)=><li key={i}>{o.Texto}</li>)}
                </ul>
            </div>
            }
        </li> 
    )
}