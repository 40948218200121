import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Input, Row, Table, Button, Modal, ModalHeader, ModalBody, CardText, Container } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Grupos } from './Grupos';
import { TiPlus } from 'react-icons/ti';
import { IoIosTrash, IoMdCreate } from 'react-icons/io';

export const GrupoDocumentos = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [texto,setTexto] = useState("");
    let [docs,setDocs] = useState([]);
    let [doc,setDoc] = useState({Id:0, Texto:''});
    let [capturando,setCapturando] = useState(false);
    let [cambios,setCambios] = useState(0);

    useEffect(() => {
        (async () => {
            cargarDocs();
        })();
    }, []);

    function setCargando(v){
        props.setCargando(v);
    }
    async function cargarDocs(){
        if(docs.length !== 0)
           return;
        setCapturando(false);
        setCargando(true);
        let url = `${edoGlobal.urlws}/documento/documentos?grupo=${props.grupo.Id}`;
        let resp = await fetch(url);
        const a = await resp.json();
        setCargando(false);
        setDocs(a);
    }
    function editar(t){
        if(!props.grupo.Abierto){
            Swal.fire('Atencion','el grupo esta cerrado','error');    
            return;
        } 
        setDoc(t);
        setTexto(t.Texto);
        setCapturando(true);
    }
    function eliminar(t){
        if(!props.grupo.Abierto){
            Swal.fire('Atencion','el grupo esta cerrado','error');    
            return;
        } 
        Swal.fire('Atencion',"Opcion aun en desarrollo",'info');
    }
    async function guardar(){
        const dato = {
            Id : doc.Id,
            Grupo : props.grupo.Id,
            Texto :texto
        }
        let url = `${edoGlobal.urlws}/documento/wsguardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            docs = [];
            cargarDocs();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    function verArchivo(d){
        let url = `${edoGlobal.urlws}/content/documentos/${edoGlobal.usuario.Institucion}/${props.periodo}/${d.Archivo}`;
        window.open(url, '', '');    
    }
    async function subir(){
        const { value: file } = await Swal.fire({
            title: 'Subir archivo',
            input: 'file',
            inputAttributes: {
              accept: '*/*',
              'aria-label': 'Seleccione archivo'
            }
          });
          
          if (file) {
            setCargando(true);
            const data = new FormData()
            data.append('file', file);
            data.append('id', doc.Id);    
            data.append('grupo', props.grupo.Id);    
            let url = `${edoGlobal.urlws}/documento/subirArchivo`;
            const resp = await fetch(url,{
                method: 'POST', // or 'PUT'
                body: data // data can be `string` or {object}!
              });
            const res = await resp.text();
            setCargando(false);
            if(res == "ok"){
                docs = [];
                cargarDocs();
            }
            else
               Swal.fire('Atencion',res,'success');    
          }        
    }
    function imagen(tipo){
        if(tipo === "pdf")
           return "pdf.png"
        if(tipo === "zip" || tipo === "rar")
           return "zip.png"
        if(tipo === "csv" || tipo.substring(0,4) === "xls")
           return "excel.png"
        if(tipo.substring(0,4) === "doc")
           return "word.png"
        if(tipo.substring(0,4) === "ppt")
           return "ppt.png";
        if(tipo.substring(0,4) === "htm" || tipo == "xml")
           return "xml.png";
        return "tabla.png"
    }
    return(
        <div>
            <Row>
                <div className='col'>
                    <table>
                        <tr style={{ verticalAlign: 'center' }}>
                            <td><span>Documentos</span></td>
                            <td><span>&nbsp;&nbsp;</span>
                                {props.grupo.Abierto && <TiPlus size={20} color='maroon' onClick={()=>{editar({Id:0,Texto:''})}}  title="nuevo documento" />}
                            </td>
                        </tr>
                    </table>
                    <Table size="sm" hover striped>
                        <thead>
                            <tr>
                                <th>Texto</th>
                                <th className='celdaFecha'>Tipo</th>
                                <th className='celdaBoton'></th>
                                <th className='celdaBoton'></th>
                                <th className='celdaBoton'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {docs.map((o, i) => {
                                const logo = "/imagenes/" + imagen(o.Tipo);
                                return (
                                    <tr key={i} >
                                        <td>{o.Texto}</td>
                                        <td>{o.Tipo}</td>
                                        <td><IoMdCreate size={20} color='maroon' onClick={()=> editar(o)}  title="editar" /></td>
                                        <td><IoIosTrash size={20} color='maroon' onClick={()=> eliminar(o)} title="eliminar" /></td>
                                        <td>{o.Archivo != "" && <img onClick={()=> verArchivo(o)} src={logo} alt="ver" title="ver archivo" />}</td>
                                     </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                <Modal isOpen={capturando} toggle={()=>setCapturando(false)}>
                <ModalHeader>
                    {doc.Id == 0 ? <span>Nuevo Documento</span> : <span>Editar Documento</span>}
                </ModalHeader>
                <Container>
                    <Row>
                        <div className='col'>
                            <span>Descripcion</span>
                            <Input type="text" size="sm" value={texto} onChange={(e)=>setTexto(e.target.value)} />
                        </div>
                    </Row>
                    <br/>
                    <Row>
                        <div className='col-2'></div>
                        <div className='col-4'>
                            <Button size="sm" color="secondary" outline onClick={()=>guardar()}>Guardar</Button>
                        </div>
                        <div className='col-4'>
                        {doc.Id != 0 && <Button size="sm" color="secondary" outline onClick={subir}>Subir archivo</Button>}
                        </div>
                    </Row>
                </Container>
                <br/>
            </Modal>
            </Row>
        </div>
    )
}