import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Modal, ModalHeader, Input, Row, Button, Table, Badge, Collapse, Card, CardHeader, CardBody, InputGroup} from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { ProgramaTema } from './ProgramaTema';
import { IoIosTrash, IoMdCreate } from 'react-icons/io';
import { TiPlus } from 'react-icons/ti';

export const ProgramaEje = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [eje, setEje] = useState({});
    let [temas, setTemas] = useState([]);
    let [actividades, setActividades] = useState([]);
    let [ponderacionEje,setPonderacionEje] = useState(props.eje.Ponderacion);
    let [sesionesEje,setSesionesEje] = useState(props.eje.Sesiones);

    let [id,setId] = useState(0);
    let [titulo,setTitulo] = useState("");
    let [texto,setTexto] = useState("");
    let [tecnica,setTecnica] = useState("");
    let [peso,setPeso] = useState(0);
    let [sesiones,setSesiones] = useState(0);
    let [orden,setOrden] = useState(0);
    let [capturando,setCapturando] = useState(false);
    let [temasAct,setTemasAct] = useState([]);
    let [tecnicasAct,setTecnicasAct] = useState([]);
    let [estrategiasAct,setEstrategiasAct] = useState([]);

    let [idAct,setIdAct] = useState(0);
    let [idTema,setIdTema] = useState(0);
    let [idSub,setIdSub] = useState(0);
    let [paginas,setPaginas] = useState([]);
    let [individual,setIndividual] = useState(false);
    let [extraClase,setExtraClase] = useState(false);
    let [capturandoAct,setCapturandoAct] = useState(false);
    let [tipoAct,setTipoAct] = useState(0);
    let [parcial,setParcial] = useState(0);
    let [x,setX] = useState(0);

    let [collapse, setCollapse] = useState(false);
    const toggle = () => setCollapse(!collapse);

    useEffect(() => {
        (async () => {
            setTemas(props.eje.Temas); //cargarEjes();
            setActividades(props.eje.Actividades); //.filter(a => a.Tipo != 'E')); //cargarEjes();
        })();
    }, [props.id]);

    async function actualizarPonderacionEje() {
        props.setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/ponderacionEje/?id=${props.eje.Id}`;
        let resp = await fetch(url);
        const d = await resp.text();
        const pondera = Number(d);
        props.setCargando(false);
        setPonderacionEje(pondera);
        props.actualizarPonderacion(props.indice,pondera)
    }
    async function actualizarSesionesEje() {
        props.setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/sesionesEje/?id=${props.eje.Id}`;
        let resp = await fetch(url);
        const d = await resp.text();
        const pondera = Number(d);
        props.setCargando(false);
        setSesionesEje(pondera);
        props.actualizarSesiones(props.indice,pondera)
    }
    async function cargarTemas() {
        props.setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/temas/?eje=${props.eje.Id}`;
        let resp = await fetch(url);
        const d = await resp.json();
        props.setCargando(false);
        setTemas(d);
    }
    async function cargarActividades() {
        props.setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/actividades/?eje=${props.eje.Id}`;
        let resp = await fetch(url);
        const d = await resp.json();
        props.setCargando(false);
        setActividades(d); //.filter(a => a.Tipo != 'E'));
    }
    async function cargarTemasActividades(a) {
        props.setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/temasSel/?eje=${props.eje.Id}&actividad=${a}`;
        let resp = await fetch(url);
        let d = await resp.json();
        setTemasAct(d);
        url = `${edoGlobal.urlws}/programaIndicativo/tecnicasSel/?eje=${props.eje.Id}&actividad=${a}`;
        resp = await fetch(url);
        d = await resp.json();
        setTecnicasAct(d);
        url = `${edoGlobal.urlws}/programaIndicativo/estrategiasSel/?eje=${props.eje.Id}&actividad=${a}`;
        resp = await fetch(url);
        d = await resp.json();
        setEstrategiasAct(d);
        props.setCargando(false);
    }

    function nuevoTema(){
        setId(0);
        setTitulo("");
        setTexto("");
        setTecnica("");
        setSesiones(1);
        setParcial(0);
        setOrden(0);
        setPeso(0);
        setCapturando(true);
    }
    function editarTema(i){
        setId(temas[i].Id);
        setTexto(temas[i].Texto);
        setTecnica(temas[i].Tecnica);
        setParcial(temas[i].Parcial);
        setOrden(temas[i].Orden);
        setSesiones(temas[i].Sesiones);
        setPeso(temas[i].Peso);
        setCapturando(true);
    }
    async function guardarTema() {
        const dato = {
            Id: id, Eje:props.eje.Id, Texto : texto, Orden:orden, Sesiones:sesiones,
            Tecnica:tecnica, Parcial:parcial, Peso: peso, Usuario : edoGlobal.usuario.Id
        }
        let url = `${edoGlobal.urlws}/programaIndicativo/wsGuardarTema`;
        props.setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        props.setCargando(false);
        if (res === "ok") {
            setCapturando(false);
            actualizarSesionesEje();
            cargarTemas();
        }
        else
            Swal.fire('Atencion', res, 'error');
    }
    async function eliminar(){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: '¿ Desea eliminar el eje tematico "' + props.eje.Texto + '" ?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                eliminarB();
        });
    }
    async function eliminarB(){
        let url = `${edoGlobal.urlws}/programaIndicativo/eliminarEje/${props.eje.Id}`;
        props.setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        props.setCargando(false);
        if (res === "ok") {
            setCapturando(false);
            props.cargarEjes();
        }
        else
            Swal.fire('Atencion', res, 'error');     
    }

    async function eliminarTema(i){
        const MySwal = withReactContent(Swal);
        console.log(temas[i]);
        MySwal.fire({
            title: 'Confirme',
            text: '¿ Desea eliminar el tema "' + temas[i].Texto + '" ?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                eliminarTemaB(i);
        });
    }
    async function eliminarTemaB(i){
        let url = `${edoGlobal.urlws}/programaIndicativo/eliminarTema/${temas[i].Id}`;
        props.setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        props.setCargando(false);
        if (res === "ok") {
            setCapturando(false);
            actualizarSesionesEje();
            cargarTemas();
        }
        else
            Swal.fire('Atencion', res, 'error');     
    }
    async function nuevaAct(){
        setIdAct(0);
        setTitulo("");
        setTexto("");
        setOrden(0);
        setPeso(0);
        setIdTema(0);
        setIdSub(0);
        setParcial(0);
        setTipoAct('');
        setIndividual(true);
        setExtraClase(false);
        setPaginas([]);
        await cargarTemasActividades(0);
        setCapturandoAct(true);
    }
    async function editarAct(i){
        setIdAct(actividades[i].Id);
        setTitulo(actividades[i].Titulo);
        setTexto(actividades[i].Texto);
        setOrden(actividades[i].Orden);
        setPeso(actividades[i].Puntos);
        setIdTema(actividades[i].Tema);
        setIdSub(actividades[i].SubTema);
        setParcial(actividades[i].Parcial);
        setTipoAct(actividades[i].Tipo);
        setPaginas(actividades[i].Paginas.split(','));
        setIndividual(actividades[i].Individual);
        setExtraClase(actividades[i].ExtraClase);
        await cargarTemasActividades(actividades[i].Id);
        setCapturandoAct(true);
    }
    function procesaSelMultiple(lista){
        let res = "";
        let sepa = "";
        for(let i=0; i < lista.length; i++)
            if(lista[i].S){
                res = res + sepa + lista[i].I;
                sepa = ","; 
            }
        return res;
    }
    async function guardarAct(){
        idTema = 0;
        let temas = procesaSelMultiple(temasAct);
        let tecnicas = procesaSelMultiple(tecnicasAct);
        let estrategias = procesaSelMultiple(estrategiasAct);
        for(let i=0; i < temasAct.length; i++)
            if(temasAct[i].S){
                idTema=temasAct[i].I;
            }
        if(tipoAct == ''){
                Swal.fire('Atencion',"debe especificar el tipo", 'error');
                return;
        }    
        if(parcial == 0){
            Swal.fire('Atencion',"debe especificar el parcial", 'error');
            return;
        }
        /*
        if(idTema == 0){
            Swal.fire('Atencion',"debe especificar al menos un tema", 'error');
            return;
        }
            */
        if(tecnicas == ""){
            Swal.fire('Atencion',"debe especificar al menos una tecnica", 'error');
            return;
        }
        if(estrategias == ""){
            Swal.fire('Atencion',"debe especificar al menos una estrategia", 'error');
            return;
        }
        const dato = {
            Id: idAct, Texto : texto, Orden:orden, Usuario : edoGlobal.usuario.Id, Parcial : parcial,
            Eje: props.eje.Id, Tema:idTema, SubTema : idSub, Puntos: peso, Individual : individual,
            Paginas:paginas.toString(), ExtraClase:extraClase, Titulo: titulo, Tipo : tipoAct,
            Temas:temas, Estrategias:estrategias, Tecnicas:tecnicas
        }
        let url = `${edoGlobal.urlws}/programaIndicativo/wsguardarActividad`;
        props.setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        props.setCargando(false);
        if (res === "ok") {
            setCapturandoAct(false);
            actualizarPonderacionEje();
            cargarActividades();
        }
        else
            Swal.fire('Atencion', res, 'error');
    }
    async function eliminarAct(i){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: '¿ Desea eliminar la actividad "' + actividades[i].Texto + '" ?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
            eliminarActB(i);
        });
    }
    async function eliminarActB(i){
        let url = `${edoGlobal.urlws}/programaIndicativo/eliminarActividad/${actividades[i].Id}`;
        props.setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        props.setCargando(false);
        if (res === "ok") {
            cargarActividades();
            actualizarPonderacionEje();
        }
        else
            Swal.fire('Atencion', res, 'error');     
    }
    async function agregarPagina(){
        const { value: text } = await Swal.fire({
            input: 'textarea',
            inputValue: "",
            inputPlaceholder: 'enlace',
            confirmButtonText: 'aceptar',
            confirmButtonColor:'silver'
        })
          
        if (text) {
            paginas.push(text);
            setX(x + 1);
        }
    }
    function quitarPagina(i){
        paginas.splice(i,1);
        setX(x + 1);
    }
    function temaSel(i,c){
        temasAct[i].S = c;
        setX(x+1);
    }
    function tecnicaSel(i,c){
        tecnicasAct[i].S = c;
        setX(x+1);
    }
    function estrategiaSel(i,c){
        estrategiasAct[i].S = c;
        setX(x+1);
    }

    return (
        <div>
            <div className='row'>
                <div className='col-10'>
                    <h4 onClick={toggle}><span className='rojo' >{props.eje.Orden}</span> - {props.eje.Texto}</h4>
                </div>
                <div className='col-1'>
                    <Badge style={{width:40}}><h5>&nbsp;{ponderacionEje}&nbsp;</h5></Badge>
                </div>
                <div className='col-1'>
                    <div className='row'>
                        <Button className="botonLince" size="sm" color="secondary" outline title="editar" onClick={props.editar}><IoMdCreate size={20} color='maroon'/></Button>
                        <span>&nbsp;</span>
                        <Button className="botonLince" size="sm" color="secondary" outline title="eliminar" onClick={props.eliminar} ><IoIosTrash size={20} color='maroon'/></Button>
                    </div>
                </div>
            </div>
            <Collapse isOpen={collapse}>
                <div style={{padding:"0px 0px 0px 10px"}}>
                    <Table borderless className='table table-sm' style={{width:'100%'}} >
                        <thead>
                            <tr style={{borderBottom:'1px solid maroon'}}>
                                <th><h5><Badge style={{color:'white',backgroundColor:'maroon'}} >Temas</Badge> &nbsp;&nbsp;&nbsp;&nbsp;  <Button className="botonLince" size="sm" color="secondary" outline onClick={() => nuevoTema()}><TiPlus size={20} color='maroon' title="nuevo tema" /><span className='negro'> Agregar tema</span></Button></h5></th>
                                <th className='celdaBoton'>Parcial</th>
                                <th className='celdaBoton'></th>
                                <th className='celdaBoton'></th>
                            </tr>
                        </thead>
                        <tbody>
                        {temas.map((o,i) => 
                        <tr key={i} >
                            <td>
                                <ProgramaTema eje={props.eje.Orden} tema={o} setCargando={props.setCargando}/>
                            </td>
                            <td>{o.Parcial}</td>
                            <td><Button className="botonLince" onClick={() => editarTema(i)} size="sm" color="secondary" outline title="editar" ><IoMdCreate size={20} color='maroon'/></Button></td>
                            <td><Button className="botonLince" onClick={() => eliminarTema(i)} size="sm" color="secondary" outline title="eliminar" ><IoIosTrash size={20} color='maroon'/></Button></td>
                        </tr>
                        )}
                        </tbody>
                    </Table>
               </div>
            </Collapse>
            <Collapse isOpen={collapse}>
                <div style={{padding:"0px 0px 0px 10px"}}>
                    <Table borderless className='table table-sm' style={{ width: '100%' }} >
                        <thead>
                            <tr style={{ borderBottom: '1px solid maroon', borderTop: '1px solid maroon' }}>
                                <th><h5><Badge style={{color:'white',backgroundColor:'maroon'}} >Actividades</Badge> &nbsp;&nbsp;&nbsp;&nbsp;  <Button className="botonLince"  size="sm" color="secondary" outline onClick={() => nuevaAct()}><TiPlus size={20} color='maroon' title="nueva actividad" /><span className='negro'> Agregar actividad</span></Button></h5></th>
                                <th>Temas</th>
                                <th>Parcial</th>
                                <th className='celdaGde'>Tipo</th>
                                <th className='celdaBoton'>Pond.</th>
                                <th className='celdaBoton'></th>
                                <th className='celdaBoton'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {actividades.map((o, i) =>
                                <tr key={i} >
                                    <td><b>{o.Tipo}-{o.Orden} - {o.Titulo}</b></td>
                                    <td className='celdaTema'><b className='rojo'>{o.Temas}</b></td>
                                    <td><b>{o.Parcial}</b></td>
                                    <td className='celdaGde'><b>{o.NomTipo}</b></td>
                                    <td><b>{o.Tipo == "P" ? "" : o.Puntos}</b></td>
                                    <td><Button className="botonLince" onClick={() => editarAct(i)} size="sm" color="secondary" outline title="editar" ><IoMdCreate size={20} color='maroon'/></Button></td>
                                    <td><Button className="botonLince" onClick={() => eliminarAct(i)} size="sm" color="secondary" outline title="eliminar" ><IoIosTrash size={20} color='maroon'/></Button></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
               </div>
            </Collapse>
            <Modal isOpen={capturando} toggle={() => setCapturando(false)}>
                <ModalHeader>
                    {id == 0 ? <span>Nuevo tema</span> : <span>Editar tema</span>}
                </ModalHeader>
                <Container>
                    <Row>
                        <div className='col'>
                            <span>Texto</span>
                            <Input type="textarea" value={texto} onChange={(e) => setTexto(e.target.value)} />
                        </div>
                    </Row>
                    <Row>
                    <div className='col-3'>
                            <b>Parcial</b>
                            <Input size="sm" type="select" value={parcial} onChange={(e) => setParcial(e.target.value)}>
                                <option value={0}>Especifique</option>
                                {props.parciales.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                            </Input>
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <div className='col-2'></div>
                        <div className='col'>
                            <Button className="botonLince" size="sm" color="secondary" outline onClick={guardarTema}>Guardar</Button>
                        </div>
                    </Row>
                </Container>
                <br />
            </Modal>
            <Modal size="lg" isOpen={capturandoAct} toggle={() => setCapturandoAct(false)}>
                <ModalHeader>
                    {idAct == 0 ? <span>Nueva actividad</span> : <span>Editando actividad</span>}
                </ModalHeader>
                <Container>
                    <br />
                    <Row>
                        <div className='col-3'>
                            <b>Parcial</b>
                            <Input size="sm" type="select" value={parcial} onChange={(e) => setParcial(e.target.value)}>
                                <option value={0}>Especifique</option>
                                {props.parciales.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                            </Input>
                        </div>
                        <div className='col-3'>
                            <b>Tipo</b>
                            <Input size="sm" type="select" value={tipoAct} onChange={(e) => setTipoAct(e.target.value)}>
                                <option value={""}>Especifique</option>
                                {props.tiposAct.map((o,i)=> <option key={i} value={o.I}>{o.N}</option>)}
                            </Input>
                        </div>
                        {tipoAct == 'A' &&
                            <>
                                <div className='col-2'>
                                    <b>Individual</b><br />
                                    <input type="checkbox" checked={individual} onChange={(e) => setIndividual(e.target.checked)} />
                                </div>
                                <div className='col-2'>
                                    <b>Extra clases</b><br />
                                    <input type="checkbox" checked={extraClase} onChange={(e) => setExtraClase(e.target.checked)} />
                                </div>
                            </>
                        }
                        {tipoAct != 'P' &&
                            <div className='col-1'>
                                <b>Ponderación</b>
                                <Input size="sm" type="text" value={peso} onChange={(e) => setPeso(e.target.value)} />
                            </div>
                        }
                    </Row>
                    <Row>
                        <div className='col'>
                            <b>Título</b>
                            <Input type="text" value={titulo} onChange={(e) => setTitulo(e.target.value)} />
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <b>Descripción</b>
                            <Input type="textarea" style={{ height: '120px' }} value={texto} onChange={(e) => setTexto(e.target.value)} />
                        </div>
                    </Row>
                    <br />
                    <Card className="cardGrupo" style={{padding:4}}>
                        <Row>
                            <div className='col'>
                                <span><b>Enlaces</b> <button onClick={agregarPagina} className='btn btn-sm btn-outline-secondary'><TiPlus size={20} color='maroon'/></button></span><br />
                                {paginas.map((o, i) => <span style={{ fontSize: 20 }}> <Badge style={{ color: 'white', backgroundColor: 'maroon' }} onClick={() => quitarPagina(i)} color="secondary">{o}</Badge>&nbsp;</span>)}
                            </div>
                        </Row>
                    </Card>
                    <br />
                    <Row>
                        <div className='col-4'>
                            <Card className="cardGrupo" style={{padding:4}}>
                                <b className='rojo'>Temas</b>
                                <div className='lista-responsive'>
                                    {temasAct.map((o, i) =>
                                        <div>
                                            <input type="checkbox" checked={o.S} onChange={(e) => temaSel(i, e.target.checked)} />
                                            <span> - {o.N}</span>
                                        </div>
                                    )}
                                </div>
                            </Card>
                            <br />
                            <Button className="botonLince" size="sm" color="secondary" outline onClick={guardarAct}>Guardar</Button>
                        </div>
                        <div className='col-4'>
                            <Card className="cardGrupo" style={{ padding: 4 }}>
                                <b className='rojo'>Estrategias de aprendizaje</b>
                                <div className='lista-responsive'>
                                    {estrategiasAct.map((o, i) =>
                                        <div>
                                            <input type="checkbox" checked={o.S} onChange={(e) => estrategiaSel(i, e.target.checked)} />
                                            <span> - {o.N}</span>
                                        </div>
                                    )}
                                </div>
                            </Card>
                        </div>
                        <div className='col-4'>
                            <Card className="cardGrupo" style={{ padding: 4 }}>
                                <b className='rojo'>Técnicas de evaluación</b>
                                <div className='lista-responsive'>
                                    {tecnicasAct.map((o, i) =>
                                        <div>
                                            <input type="checkbox" checked={o.S} onChange={(e) => tecnicaSel(i, e.target.checked)} />
                                            <span> - {o.N}</span>
                                        </div>
                                    )}
                                </div>
                            </Card>
                        </div>
                    </Row>
                </Container>
                <br />
            </Modal>
        </div>
    )
}