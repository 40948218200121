import React, { useContext } from 'react';
import { FaPeopleArrows, FaTasks, FaThLarge } from 'react-icons/fa';
import { IoBarChart } from 'react-icons/io5';
import { Card, CardBody } from 'reactstrap';
import { AppContext } from './AppContext';

export const Modulos = (props) => {
    const edoGlobal = useContext(AppContext);
    console.log(edoGlobal.usuario);
    return(
        <div className="container" style={{flex:1, verticalAlign:'center', alignContent:'center', alignItems:'center'}}>
            <br/><br/><br/>
            <div className="row">
                <div className='col-1'></div>
                <div className="col-2 cardGrupo" style={{textAlign :'center'}}>
                    <Card className='cardTema' style={{height:150}} onClick={()=> props.opcionSel('grupos','Grupos')}>
                        <CardBody>
                        <FaThLarge size={58} color='maroon'/>
                        <br /><br />
                        <span className='rojo'>administración de grupos</span>
                        </CardBody>
                    </Card>
                </div>
                {edoGlobal.usuario.Tutor && 
                <div className="col-2 cardGrupo" style={{ textAlign: 'center' }}>
                    <Card className='cardTema' style={{height:150}} onClick={() => props.opcionSel('tutorias', 'Tutorias')}>
                        <CardBody>
                            <FaPeopleArrows size={58} color='maroon'/>
                            <br /><br />
                            <span className='rojo'>tutorias</span>
                        </CardBody>
                    </Card>
                </div>
                }
                {edoGlobal.usuario.Silabo &&
                <div className="col-2 cardGrupo" style={{ textAlign: 'center' }}>
                    <Card className='cardTema' style={{height:150}} onClick={() => props.opcionSel('silabos', 'Diseño de syllabus')}>
                        <CardBody>
                            <FaTasks size={58} color='maroon'/>
                            <br /><br />
                            <span className='rojo'>diseño de syllabus</span>
                        </CardBody>
                    </Card>
                </div>
                }
                {edoGlobal.usuario.Informes &&
                <div className="col-2" style={{ textAlign: 'center' }}>
                    <Card className='cardTema' style={{height:150}}>
                        <CardBody>
                            <IoBarChart size={58} color='maroon'/>
                            <br /><br />
                            <span className='rojo'>informes</span>
                        </CardBody>
                    </Card>
                </div>
                }
            </div>
        </div>
    )
}